// receber_horas.vue
<template id="receber_horas_temp">
  <div id="receber_horas_temp">

    <form id="receber-horas-form-id"
              novalidate="novalidate"
              class="simple_form receber-horas"
              :action="url"
              data-remote="true"
              method="post"
              accept-charset="UTF-8">


      <div class='modal-header'>
        <h4 class='modal-title'>Receber horas/valores do banco</h4>
      </div>
      <div class='modal-body'>

        <input name="utf8" type="hidden" value="✓">
        <input name="authenticity_token"
               type="hidden"
               id="authenticity_token">
        <input type="hidden"
               name='tarefa_id'
               :value="tarefa_id">

        <div class="row">

          <table  class="table table-inner table-vmiddle"
                  id="table_horas">
            <thead>
              <tr>
                <th class="text-left" style="width: 30%">Nome</th>
                <th class="text-left" style="width:  5%">Unidade</th>
                <th class="text-right" style="width:  5%">Custo</th>
                <th class="text-right" style="width: 15%">Disponivel</th>
                <th class="text-right" style="width: 10%">Receber</th>
                <th class="text-right" style="width: 15%">Para insumo</th>
                <th class="text-right" style="width: 15%">Em R$</th>
              </tr>
            </thead>
            <tbody id="membros">
              <tr v-for="(banco, index) in bancos">

                <td class="p-t-0 p-b-0 text-left">
                  <input type="hidden"
                         :name="controlName('receber[][id]',index)"
                         :value="banco.tipo_insumo_id">
                  {{ banco.tipo_insumo_nome }}
                </td>
                <td class="p-t-0 p-b-0 text-left">{{ banco.unidade }}</td>
                <td class="p-t-0 p-b-0 text-right">{{ number2strBr( banco.valor_unit, 2 ) }}</td>
                <td class="p-t-0 p-b-0 text-right">{{ number2strBr( banco.quantidade, 4 ) }}</td>
                <td class="p-t-0 p-b-0 text-right">

                  <input type="hidden"
                         :name="controlName('receber[][valor_unit]',index)"
                         :value="bancos[index].valor_unit" >
                  <input type="hidden"
                         :name="controlName('receber[][receber]',index)"
                         :value="bancos[index].doar" >
                  <input type="hidden"
                         :name="controlName('receber[][tipo_insumo_id]',index)"
                         v-model="bancos[index].tipo_insumo_id" >
                  <input type="hidden"
                         :name="controlName('receber[][insumo_destinatario]',index)"
                         v-model="bancos[index].destinatario" >

                  <vue-numeric
                    class="form-control banco"
                    separator="." :precision="4"
                    v-model="banco.doar"
                    value="banco.doar"
                    :min="0.0"
                    :max="banco.quantidade"
                    :id="'horas_' + index + banco.id"
                    :on:change="alocaDoacao(index)"
                  ></vue-numeric>
                </td>
                <td class="p-t-0 p-b-0 text-right">

                  <select class="select select2 input-control"
                          v-model="banco.destinatario"
                          style="width: inherit;"
                          :on:change="alocaDoacao(index)"
                          :id="'select_dest_' + index">
                    <option v-for="dest in banco.destinatarios"
                            v-bind:value="dest.value">
                      {{ dest.label }}
                    </option>
                  </select>

                </td>
                <td class="p-t-0 p-b-0 text-right">{{ doacaoEmReal(index) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
<!--
        <table-apos
           :insumos_apos="insumos_apos"
           :model_name="model_name"
           :key="refreshInsuAposTable"
        >
        </table-apos>
 -->
      </div>
      <div class="modal-footer">
        <div class="col-sm-2">
          <a class="btn" data-dismiss="modal" href="#">Fechar</a>
        </div>
        <div class="col-sm-10">
          <button class="btn btn-primary"
                  name="doit"
                  type="submit">Confirmar a doação
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  import numberMask from 'packs/components/mixins/numberMask.vue'
  import CmpSelect2 from 'packs/components/comp/cmp-select2.vue'
  import VueNumeric from 'vue-numeric';

  import TableApos from './table_apos.vue'

  export default {
    name: 'receber_horas',
    mixins: [inputUtils, numberMask],
    props: ['tarefa_id', 'horasdisp', 'bancodisp'],
    components: {
      'vue-numeric'          : VueNumeric,
      'cmp-select2'          : CmpSelect2,
      'table-apos'           : TableApos
    },
    data: function () {
      return {
        model_name: '',
        loaded: false,
        bancos: [],
        insumos_apos: [],
        refreshInsuAposTable: 0
      }
    },
    mounted: function () {
      moment.locale('pt-br');

      var csrftoken = $('meta[name="csrf-token"]').attr('content');
      $('input#authenticity_token').val(csrftoken) ;

      this.insumos_apos = this.horasdisp ;
      this.bancos       = this.bancodisp ;
      // var that = this;
      // this.bancodisp.forEach((banco, index) => {
      //   if (banco.destinatarios.length == 1) {
      //     var banc_dest = that.bancos[index]
      //     banc_dest.destinatario =

      //   }

      //
      //   that.$set(banc_dest, 'destinatarios', banco.destinatarios) ;
      // });
    },
    watch: {
      // equipe_id: function(new_equipe_id) {
      //   if (this.loaded) {
      //     this.atuaEquipe(new_equipe_id) ;
      //   }
      // }
    },
    computed: {
      url: function () {
        return '/horas_banco/' + this.tarefa_id + '/receber' ;
      }
    },


    methods: {
      saldoBancosNum: function(index) {
        return this.bancos[index].quantidade - this.bancos[index].doar ;
      },
      saldoBanco: function(index) {
        return this.number2strBr( this.saldoBancosNum(index), 4 );
      },
      doacaoEmReal: function(index) {
        return this.number2strBr( this.bancos[index].doar * this.bancos[index].valor_unit, 2 ) ;
      },
      totalFinalNum: function(index) {
        return this.insumos_apos[index].quantidade - this.insumos_apos[index].doar ;
      },
      totalFinal: function(index) {
        return this.number2strBr( this.totalFinalNum(index), 4 );
      },
      totalInsuNum: function(index) {
        return this.insumos_apos[index].quantidade + this.insumos_apos[index].doar ;
      },
      alocaDoacao: function(alt_index) {
        var that = this
        // debugger
        // this.insumos_apos.forEach((insu, index) => {
        //   insu.doar = 0.0 ;
        // });



        // this.bancos.forEach((banco, index) => {
          var banco = this.bancos[alt_index];


          // if (banco.doar > 0) {
          if ( 1 == 1) {
            // Busca insumo
            var index_insumo_dest = -1
            var insu_dest = null
            var multiplicador = 1  // Valor unitário
            that.insumos_apos.forEach((insu, index) => {
              if ((insu.insumo_id == banco.destinatario) && (index_insumo_dest == -1)) {
                index_insumo_dest = index;
                insu_dest = insu;
              }
            });
            // Alocar numa verba que ainda não existe?
            if ((index_insumo_dest == -1) && (banco.destinatario == -88)) {
              var insumoverba = {
                id:   -88,
                insumo_id: -88,
                nome: 'Verba (R$)',
                unidade: 'R$',
                quantidade: 0,
                doar:   0.0,
                saldo:  0.0,
                pode_doar: 0
              }
              that.insumos_apos.push( insumoverba );
              index_insumo_dest = this.insumos_apos.lenhth - 1;
              insu_dest = this.insumos_apos[index_insumo_dest] ;
            }
            if (banco.destinatario == -88) {
              multiplicador = banco.valor_unit ;
            }


            if (insu_dest) {
              insu_dest.doar = insu_dest.doar + ( banco.doar * multiplicador ) ;
            }


          }


        // });



      }

    }
  }


</script>
