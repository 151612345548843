var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-3" }, [
      _c(
        "div",
        {
          staticClass: "form-group datepicker required acao_data_prevista",
          class: {
            "has-error": _vm.errorsStr(_vm.options.errors, "data_prevista")
          }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("cmp-datepicker", {
            attrs: { id: "acao_data_prevista", name: _vm.input_data_name },
            model: {
              value: _vm.data_prevista,
              callback: function($$v) {
                _vm.data_prevista = $$v
              },
              expression: "data_prevista"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "help-block" }, [
            _vm._v(_vm._s(_vm.errorsStr(_vm.options.errors, "data_prevista")))
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.new_record
      ? _c("div", { staticClass: "col-sm-9" }, [
          _c(
            "div",
            { staticClass: "form-group repeticao" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label repeticao",
                  attrs: { for: "repeticao" }
                },
                [
                  _vm._v(
                    "\n        Gerar múltiplas Ações periódicas. Frequência:\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("cmp-v-select", {
                attrs: {
                  input_class: "form-control select select2",
                  options: _vm.opcoes_repeat,
                  required: true,
                  name: "acao[repeat_selected]",
                  value: _vm.repeat_selected
                },
                model: {
                  value: _vm.repeat_selected,
                  callback: function($$v) {
                    _vm.repeat_selected = $$v
                  },
                  expression: "repeat_selected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.repeat_selected > 0
            ? _c("div", { attrs: { id: "repeat_params" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 form-group datepicker required acao_data_limite"
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("cmp-datepicker", {
                      attrs: {
                        id: "acao_data_limite",
                        name: "acao[data_limite]"
                      },
                      model: {
                        value: _vm.data_limite,
                        callback: function($$v) {
                          _vm.data_limite = $$v
                        },
                        expression: "data_limite"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 form-group text optional repeat_number"
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("cmp-autonumeric", {
                      attrs: {
                        required: true,
                        name: "acao[numero_limite]",
                        id: "repeat_numero_limite",
                        decimal_places: 0,
                        min: 2,
                        max: 100
                      },
                      model: {
                        value: _vm.numero_limite,
                        callback: function($$v) {
                          _vm.numero_limite = $$v
                        },
                        expression: "numero_limite"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label datepicker required",
        attrs: { for: "acao_data_prevista" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n        Data prevista\n      ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label datepicker required",
        attrs: { for: "acao_data_limite" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n          Gerar ações até\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label text optional",
        attrs: { for: "repeat_number" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n          Número máximo de ações à gerar\n        ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }