<template>
  <div class="modal-content">
    <div class="modal-header" id="modal-header">
      <form-cab :header="options.form_header"></form-cab>
    </div class="modal-header">
    <div class="modal-body row" id="modal-body">
      <form novalidate="novalidate" class="simple_form edit_anexo"
            id="edit_anexo"
            :action="options.url"
            method="put"

        <div class="col-sm-6">
          <h4>Imagem atual</h4>
          <div>
            <img :src="options.image_url"
                 alt="Mapa"
                 style="border-style: solid; border-width: 1px; max-height: 250px; max-width: 350px;">
            </div>
        </div>
        <div class="col-sm-6">
          <cmp-dropzone
             id="dropzone"
             initmsg="Clique ou arraste a imagem do mapa"
             :url="options.url"
             :method="options.method"
             :as="options.as"
             :token="options.token">
          </cmp-dropzone>
        </div>
      </form>

    </div>

    <div class="modal-footer">
      <div class="pull-right">
        <div class="btn-group">
          <a type="button"
             class="btn btn-w-m btn-default"
             data-dismiss="modal"
          >Fechar</a>
        </div>
      </div>
    </div class="modal-footer">

  </div>
</template>

<script>

  import CmpDropzone    from 'packs/components/comp/cmp-dropzone.vue'
  import FormCab        from 'packs/components/form/form-cab.vue'

  // import 'vue2-dropzone/dist/vue2Dropzone.css' ;

  export default {
    name: 'EditModalMapa',
    components: {
      'form-cab': FormCab,
      'cmp-dropzone': CmpDropzone
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'VueEditMapa',
      }
    },
    mounted: function() {
    },
    methods: {
    },
    computed: {
    }

  };
</script>
