<template>
  <div class="panel panel-default"
       :style="hideOrShowStyle">
    <div v-show="group.caption != ''"
         class="panel-heading"
         style="display: flex;">

      <div class="title" style="float:left;">
        <h5 >{{ group.caption }}</h5>
      </div>

      <div class="ibox-tools">
      </div>

    </div>
    <div class="panel-body"
         aria-expanded="true"
         :style="hideOrShowStyle"
         :id="'render-group-body-' + index">

      <div class="fields-group"
           ref="render_slot">

        <component v-for="(field, index) in json_fields"
                   :is="renderFieldName(field)"
                   :key="index"
                   :field="field"
                   :model_name="model_name_2ex"
                   :value="fieldValue(field)"
                   :error="fieldError(field)"
                   :store_name="options.storename"
                   :action_options="options2filed"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script>

  import RenderHeader            from "packs/components/form/render_fields/render_header.vue";
  import RenderTextText          from "packs/components/form/render_fields/render_text_text.vue";
  import RenderDate              from "packs/components/form/render_fields/render_date.vue";
  import RenderDateTime          from "packs/components/form/render_fields/render_datetime.vue";
  import RenderTextareaTextarea  from "packs/components/form/render_fields/render_textarea_textarea.vue";
  import RenderSelect            from "packs/components/form/render_fields/render_select.vue";
  import RenderCheckboxGroup     from "packs/components/form/render_fields/render_checkbox_group.vue";
  import RenderRadioGroup        from "packs/components/form/render_fields/render_radio_group.vue";
  import RenderNumber            from "packs/components/form/render_fields/render_number.vue";
  import RenderParagraph         from "packs/components/form/render_fields/render_paragraph.vue";
  import RenderFileFile          from "packs/components/form/render_fields/render_file_file.vue";


  export default {
    name: 'GroupRender',
    components: {
      'render-header'             : RenderHeader,
      'render-text-text'          : RenderTextText,
      'render-date'               : RenderDate,
      'render-datetime'           : RenderDateTime,
      'render-textarea-textarea'  : RenderTextareaTextarea,
      'render-checkbox-group'     : RenderCheckboxGroup,
      'render-radio-group'        : RenderRadioGroup,
      'render-select'             : RenderSelect,
      'render-number'             : RenderNumber,
      'render-paragraph'          : RenderParagraph,
      'render-file-file'          : RenderFileFile
    },
    // props: ['group', 'index', 'model_name', 'hide_panel'],
    props: ['group_opts','options', 'model', 'errors'],
    data: function () {
      return {
        klass: 're-group',

        eleRender : null,
        fbRender : null,

        group: this.group_opts.group,
        index: this.group_opts.index,
        model_name: this.options.model_name,
        hide_panel: this.options.hide_panel
      }
    },

    mounted: function() {
    },

    methods: {
      renderFieldName: function(field) {

        var subtype = (field.subtype ? ('-' + field.subtype) : '')
        subtype = ((field.type != 'header') ? subtype : '')
        subtype = ((field.type != 'paragraph') ? subtype : '')
        subtype = ((field.type == 'file') ? '-file' : subtype); // Para file só temos file-file

        subtype = ((field.dateType && (field.dateType == 'datetime')) ? 'time' : subtype)

        return 'render-' + field.type + subtype ;
      },
      fieldValue: function(field) {
        if (typeof(field.name) == 'undefined') {
          return null
        }
        if ( (this.modelObject) &&
             (typeof(this.modelObject[field.name]) !== 'undefined') ) {
          return this.modelObject[field.name] ;
        }
        if ( (this.model) &&
             (typeof(this.model[field.name]) !== 'undefined') ) {
          return this.model[field.name] ;
        }
      },
      fieldError: function(field) {
        if (typeof(field.name) == 'undefined') {
          return null
        }
        if ( (this.errors) &&
             (typeof(this.errors[field.name]) !== 'undefined') ) {
          return this.errors[field.name] ;
        } else {
          return null
        }
      },
      strName: function(field) {
        return this.options.storename ;
      }
    },
    computed: {
      storename: function() {
        return this.options.storename ;
      },
      storeBase: function() {
        return this.$store.getters[this.options.storename + '/fullForm']
      },
      modelObject: function () {
        return this.storeBase.model ;
      },
      collections: function() {
        return this.storeBase.formulario.collections ;
      },
      json_fields: {
        get: function() {
          return JSON.parse(this.group.fields) ;
        }
      },
      model_name_2ex: function() {
        return (this.options.model_name || '')
      },
      options2filed: function() {
        let data = {}
        if (this.options.model_url) {
          data['url'] = this.options.model_url
        } else {
          data['url'] = this.storeBase.create_url
        }
        return data
      },
      hideOrShowStyle: function() {
        var sty = ''
        if (this.hide_panel) {
          sty += 'border-width: 0;'
          sty += 'border-style: none;'
          sty += 'padding: 0;'
        }
        return sty;
      }
    }
  };
</script>

