var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-content" }, [
    _c(
      "div",
      { staticClass: "modal-header", attrs: { id: "modal-header" } },
      [_c("form-cab", { attrs: { header: _vm.options.form_header } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-body row", attrs: { id: "modal-body" } }, [
      _c(
        "form",
        {
          staticClass: "simple_form edit_anexo",
          attrs: { novalidate: "novalidate", id: "nova_versao" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group string optional arquivo_numero" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label string optional",
                    attrs: { for: "arquivo_numero" }
                  },
                  [_vm._v("Numero")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.arquivo.numero,
                      expression: "arquivo.numero"
                    }
                  ],
                  staticClass: "form-control string optional",
                  attrs: {
                    type: "text",
                    value: "",
                    name: "arquivo[numero]",
                    id: "arquivo_numero"
                  },
                  domProps: { value: _vm.arquivo.numero },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.arquivo, "numero", $event.target.value)
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              { staticClass: "form-group datepicker optional arquivo_emissao" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label datepicker optional",
                    attrs: { for: "arquivo_emissao" }
                  },
                  [_vm._v("Emissão")]
                ),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  attrs: {
                    id: "arquivo_emissao",
                    value: _vm.arquivo.emissao || ""
                  },
                  model: {
                    value: _vm.arquivo.emissao,
                    callback: function($$v) {
                      _vm.$set(_vm.arquivo, "emissao", $$v)
                    },
                    expression: "arquivo.emissao"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                staticClass: "form-group datepicker optional arquivo_validade"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label datepicker optional",
                    attrs: { for: "arquivo_validade" }
                  },
                  [_vm._v("Validade")]
                ),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  attrs: {
                    id: "arquivo_validade",
                    value: _vm.arquivo.validade || ""
                  },
                  model: {
                    value: _vm.arquivo.validade,
                    callback: function($$v) {
                      _vm.$set(_vm.arquivo, "validade", $$v)
                    },
                    expression: "arquivo.validade"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "form-group string optional arquivo_dias_para_renovacao"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label string optional",
                    attrs: { for: "arquivo_dias_para_renovacao" }
                  },
                  [_vm._v("Dias para renovação")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.arquivo.dias_para_renovacao,
                      expression: "arquivo.dias_para_renovacao"
                    }
                  ],
                  staticClass: "form-control string optional",
                  attrs: {
                    type: "text",
                    value: "",
                    name: "arquivo[dias_para_renovacao]",
                    id: "arquivo_dias_para_renovacao"
                  },
                  domProps: { value: _vm.arquivo.dias_para_renovacao },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.arquivo,
                        "dias_para_renovacao",
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "form-group text optional arquivo_local_arquivo_id"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label text optional",
                    attrs: { for: "arquivo_local_arquivo_id" }
                  },
                  [_vm._v("Arquivado em")]
                ),
                _vm._v(" "),
                _c("cmp-v-select", {
                  attrs: {
                    input_class: "form-control select select2",
                    options: _vm.options.local_arquivos,
                    required: false,
                    multiple: false,
                    value: _vm.arquivo.local_arquivo_id
                  },
                  model: {
                    value: _vm.arquivo.local_arquivo_id,
                    callback: function($$v) {
                      _vm.$set(_vm.arquivo, "local_arquivo_id", $$v)
                    },
                    expression: "arquivo.local_arquivo_id"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("vue-dropzone", {
                ref: "myVueDropzone",
                attrs: { id: "dropzone", options: _vm.dzOptions() },
                on: {
                  "vdropzone-sending": _vm.addParams,
                  "vdropzone-success": _vm.oneSucess,
                  "vdropzone-error": _vm.errorInspect
                }
              })
            ],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "pull-right" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-w-m btn-primary active m-r-5",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.saveVersao()
                }
              }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.saveVersaoBtnCaption()) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-w-m btn-default",
              attrs: { type: "button", "data-dismiss": "modal" }
            },
            [_vm._v("Fechar")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("h3", [_vm._v("Documento")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }