<template>
  <div :class="klass">

    <div v-if="options.title_label" class="col-sm-12">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          {{ options.title_label }}
        </label>
        <input type="text"
               class="form-control string optional"
               readonly
               :value="options.title_value">
      </div>
    </div>

    <div v-if="options.ask_data_vencimento" class="col-sm-12">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          {{ options.data_vencimento_label }}
        </label>

        <cmp-datepicker
          id="input_data_vencimento"
          name="input_data_vencimento"
          v-model="data_vencimento">
        </cmp-datepicker>

      </div>
    </div>

    <div v-if="options.ask_select" class="col-sm-12">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          {{ options.ask_select.title }}
        </label>
<!--
        <cmp-v-select
          input_class="form-control select select2"
          :options="options.options"
          :required="true"
          name="ask_select[selected]"
          :value="options.ask_select.value"
          v-model="ask_select_selected">
        </cmp-v-select>
 -->
      </div>
    </div>

    <div class="col-sm-12">
      <vue-dropzone ref="myVueDropzone"
                    id="dropzone"
                    :options="dz_options"
                    :language="langOptions"
                    v-on:vdropzone-sending="addParams"
                    v-on:vdropzone-params="params"
                    v-on:vdropzone-success="successUp"
                    v-on:vdropzone-error="errorUp"
                    dictDefaultMessage="Novo Texto">
      </vue-dropzone>
    </div>

  </div>
</template>

<script>

  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'
  import inputUtils from 'packs/components/mixins/inputUtils.vue'

  import vue2Dropzone from 'vue2-dropzone' ;
  // import 'vue2-dropzone/dist/vue2Dropzone.css' ;

  export default {
    name: 'UploadFoto',
    mixins: [inputUtils],
    components: {
      'cmp-datepicker': CompDatePicker,
      'cmp-v-select'  : CmpVSelect,
      'vue-dropzone': vue2Dropzone
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'upload_arquivo',
        opts: this.options,

        errors: [],

        selected_obra: null,
        data_vencimento: null,

        ask_select_selected: null,

        dzOptions: {
        },
        langOptions: {
          dictDefaultMessage: "Novo Texto"
        }
      }
    },
    methods: {
      params: function() {
        return {
          param3: 'pepepe'
        }
      },
      addParams: function (file, xhr, formData) {
        formData.append( 'authenticity_token', this.opts.form_token);

        if (this.options.ask_data_vencimento) {
          formData.append( 'vencimento', this.data_vencimento);
        }

        if (this.options.ask_select) {
          formData.append( 'ask_select_id', this.ask_select_selected);
        }

        // formData.append( 'user_id',     this.opts.user_id)
        // formData.append( 'obra_id',     this.opts.obra_id)
        // formData.append( 'equipe_id',   this.opts.equipe_id)
        // formData.append( 'map_id',      this.opts.map_id)
        // formData.append( 'tipo',        this.opts.tipo)
        // if (this.opts.tipo !== 'orca') {
        //   formData.append( 'obra_id',     this.opts.obra_id)
        // } else {  // Orçamento
        //   formData.append( 'obra_id',     this.selected_obra.value)
        //   formData.append( 'solum_old',     this.opts.solum_old)
        //   formData.append( 'solum_new',     this.opts.solum_new)
        // }

      },
      successUp: function(file, res) {
        vm.alert('success', res.msg) ;
        if (this.opts.hide_modal_on_sucess) {
          $("#modal-main").modal("hide");
        }
        this.errors = []
        this.refresh_table()
      },
      errorUp: function(file, res, xhr) {

        this.errors = res.errors
        vm.alert('warning', res.errors[0]) ;
      },

      refresh_table: function() {
        var that = this;

        if (that.options.refresh_dom) {
          window.vm.refresh(that.options.refresh_dom);
        } else {
          window.vm.refresh();
        }
        // $.ajax({
        //   method: 'GET',
        //   url: that.options.refresh_url
        // });
      }
    },
    computed: {
      dz_options: function() {
        var opts = {
          url: this.options.url,
          uploadMultiple: false,
          paramName: this.options.as,
          dictDefaultMessage: 'Clique ou arraste a imagem',
          language: {
            dictDefaultMessage: "Novo Texto"
          }
        } ;
        if (this.options.default_message) {
          opts.dictDefaultMessage = this.options.default_message ;
        }
        if (this.options.method) {
          opts.method = this.options.method ;
        }
        return opts ;
      }
    }

  };
</script>
