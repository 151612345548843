var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12" },
    [
      _c("h4", [_vm._v("Datas para treinamento desta turma")]),
      _vm._v(" "),
      _vm._l(_vm.datas, function(data, index) {
        return _c("div", { key: "treinadata-" + index }, [
          _c("div", { staticClass: "row" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-sm-4 form-group datepicker required turma_data"
            },
            [
              index == 0
                ? _c(
                    "label",
                    {
                      staticClass: "control-label datepicker required",
                      attrs: { for: "turma_data" }
                    },
                    [
                      _c("abbr", { attrs: { title: "obrigatorio" } }, [
                        _vm._v("*\n        ")
                      ]),
                      _vm._v(" Data\n      ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("cmp-datepicker", {
                attrs: {
                  id: "turma_data",
                  name: _vm.controlName("[datas][][data]", index),
                  id: _vm.controlId("data", index),
                  min_date_desativada: "hoje()"
                },
                model: {
                  value: data.data,
                  callback: function($$v) {
                    _vm.$set(data, "data", $$v)
                  },
                  expression: "data.data"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-sm-4 form-group text optional turma_carga_horaria"
            },
            [
              index == 0
                ? _c(
                    "label",
                    {
                      staticClass: "control-label text optional",
                      attrs: { for: "turma_carga_horaria" }
                    },
                    [_vm._v("\n        Carga horária\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("cmp-autonumeric", {
                attrs: {
                  required: true,
                  name: _vm.controlName("[datas][][carga_horaria]", index),
                  id: _vm.controlId("carga_horaria", index),
                  value: data.carga_horaria || "0",
                  decimal_places: 2,
                  min: 0,
                  max: 24
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { class: index == 0 ? "p-t-20" : "" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-danger",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.removeData(index)
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-trash fa-hc-fw m-r-5" }),
                _vm._v("\n        Remover data\n      ")
              ]
            )
          ])
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.appendData()
              }
            }
          },
          [
            _c("i", { staticClass: "fa fa-plus fa-hc-fw m-r-5" }),
            _vm._v("\n      Adicionar data\n    ")
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }