// errorsStr.vue
<script>
  export default {
    methods: {
      errorsStr: function(err_obj, fields) {
        var afields = (! $.isArray(fields)) ? [fields] : fields ;
        var ret = [] ;
        if (err_obj == undefined) { return null ;}
        afields.forEach((field, index) => {
          if (err_obj[field] && (err_obj[field].length > 0)) {
            ret = ret.concat(err_obj[field]);
          }
        })
        return ret.join(',') ;
      },
      arr_errorsStr: function(key,index) {
        var ret = '' ;
        // Não funcionando ok  :-(
        if (this.errors[key]) {
          var errors = this.errors[key] ;
          errors.forEach((field, index) => {
            if (field["id"] == index.toString) {
              ret = field["msg"];
            }
          })
        }
        return ret ;
      },
      readErrors: function (errors, formErrKey, errKey) {
        var aformErrKey = (! $.isArray(formErrKey)) ? [formErrKey] : formErrKey ;
        errKey = errKey || formErrKey
        aformErrKey.forEach((fKey) => {
          this.$set(this.errors,fKey ,errors[fKey]) ;
        });
      },
      loadErrors: function (a_errors) {
        a_errors = typeof a_errors !== 'undefined' ? a_errors : '[]' ;
        return JSON.parse(a_errors) ;
      }

    }
  }
</script>

