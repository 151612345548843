import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
Vue.use(Vuex);

// import FormularioStore    from 'packs/vuex/stores/formulario_store.js';
import FormDataStore      from 'packs/vuex/stores/form_data_store.js';

const modal_store = new Vuex.Store({
  modules: {
    // FormularioStore,
    FormDataStore
  },
  state: {
    current_user: {
      id: 0,
      email: ''
    }
  }
});

export default modal_store;
