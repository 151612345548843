var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-content" }, [
    _c(
      "div",
      { staticClass: "modal-header", attrs: { id: "modal-header" } },
      [_c("form-cab", { attrs: { header: _vm.options.form_header } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-body row", attrs: { id: "modal-body" } }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("h4", [_vm._v("Imagem atual")]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticStyle: {
              "border-style": "solid",
              "border-width": "1px",
              "max-height": "250px",
              "max-width": "350px"
            },
            attrs: { src: _vm.options.image_url, alt: "Mapa" }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("cmp-dropzone", {
            attrs: {
              id: "dropzone",
              initmsg: "Clique ou arraste a imagem do mapa",
              url: _vm.options.url,
              method: _vm.options.method,
              as: _vm.options.as,
              token: _vm.options.token
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "pull-right" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-w-m btn-default",
              attrs: { type: "button", "data-dismiss": "modal" }
            },
            [_vm._v("Fechar")]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }