var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "index-history" } }, [
    _vm.temHistorico
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-8" }, [
            _c("h4", [_vm._v("Registro da alteração")]),
            _vm._v(" "),
            _c("div", { staticClass: "pmbb-view" }, [
              _c("dl", { staticClass: "dl-horizontal" }, [
                _c("dt", [_vm._v("Quando")]),
                _vm._v(" "),
                _c("dd", [_vm._v(" " + _vm._s(_vm.versao.created_at) + " ")]),
                _vm._v(" "),
                _c("dt", [_vm._v("Operação")]),
                _vm._v(" "),
                _c("dd", [_vm._v(" " + _vm._s(_vm.versao.action_name) + " ")]),
                _vm._v(" "),
                _c("dt", [_vm._v("Autor")]),
                _vm._v(" "),
                _c("dd", [
                  _c("a", { attrs: { href: _vm.user_link } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.versao.user.nome) +
                        "\n            "
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "popover-title" }, [
                  _vm._v("Mudanças")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content", attrs: { id: "diff-area" } },
                  [
                    _c("div", { staticClass: "pre" }, [
                      _c("table", { staticClass: "table" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.changedkey, function(at) {
                            return _c("tr", [
                              _c("th", [
                                _vm._v(
                                  " " + _vm._s(_vm.translate_attr(at)) + " "
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.object_to_str(
                                        _vm.versao.changeset[at][0]
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.object_to_str(
                                        _vm.versao.changeset[at][1]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              { staticClass: "listview lv-user" },
              _vm._l(_vm.reverse, function(ver) {
                return _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.selectVer(ver)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "lv-item.media" }, [
                      _c(
                        "div",
                        { staticClass: "lv-avatar bgm-orange pull-left" },
                        [_vm._v(" " + _vm._s(_vm.first_letter(ver)) + " ")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", { staticClass: "lv-title" }, [
                          _vm._v(" " + _vm._s(_vm.ver_action_name(ver)) + " ")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lv-small" }, [
                          _vm._v(" " + _vm._s(ver.created_at) + " ")
                        ])
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _c("div", { staticClass: "row" }, [
          _c("h4", { staticClass: "pull-center" }, [
            _vm._v("Sem registro de histórico")
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Atributo")]),
        _vm._v(" "),
        _c("th", [_vm._v("Antes")]),
        _vm._v(" "),
        _c("th", [_vm._v("Depois")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }