var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-content" }, [
    _c(
      "div",
      { staticClass: "modal-header", attrs: { id: "modal-header" } },
      [_c("form-cab", { attrs: { header: _vm.options.form_header } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-body row", attrs: { id: "modal-body" } }, [
      _c(
        "form",
        {
          staticClass: "simple_form edit_anexo",
          attrs: { novalidate: "novalidate", id: "edit_anexo" }
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group string optional arquivo_title" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label string optional",
                    attrs: { for: "arquivo_title" }
                  },
                  [_vm._v("\n              Descrição\n            ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.anexo.descricao,
                      expression: "anexo.descricao"
                    }
                  ],
                  staticClass: "form-control string optional",
                  attrs: {
                    type: "text",
                    name: "arquivo[title]",
                    id: "arquivo_title"
                  },
                  domProps: { value: _vm.anexo.descricao },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.anexo, "descricao", $event.target.value)
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group string optional arquivo_title" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label string optional",
                    attrs: { for: "arquivo_title" }
                  },
                  [_vm._v("\n              Responsável\n            ")]
                ),
                _vm._v(" "),
                _c("cmp-v-select", {
                  attrs: {
                    input_class: "form-control select select2",
                    options: _vm.options.responsaveis_col,
                    required: false,
                    multiple: false,
                    value: _vm.anexo.responsavel_id
                  },
                  model: {
                    value: _vm.anexo.responsavel_id,
                    callback: function($$v) {
                      _vm.$set(_vm.anexo, "responsavel_id", $$v)
                    },
                    expression: "anexo.responsavel_id"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              {
                staticClass: "form-group text optional arquivo_local_arquivo_id"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label text optional",
                    attrs: { for: "arquivo_local_arquivo_id" }
                  },
                  [_vm._v("Arquivado em")]
                ),
                _vm._v(" "),
                _c("cmp-v-select", {
                  attrs: {
                    input_class: "form-control select select2",
                    options: _vm.options.local_arquivos,
                    required: false,
                    multiple: false,
                    value: _vm.arquivo.local_arquivo_id
                  },
                  model: {
                    value: _vm.arquivo.local_arquivo_id,
                    callback: function($$v) {
                      _vm.$set(_vm.arquivo, "local_arquivo_id", $$v)
                    },
                    expression: "arquivo.local_arquivo_id"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.options.upload_file
            ? _c("div", { staticClass: "col-sm-12" }, [
                _c("h3", [_vm._v("Documento")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.options.upload_file
            ? _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group string optional arquivo_numero" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label string optional",
                        attrs: { for: "arquivo_numero" }
                      },
                      [_vm._v("Numero")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.arquivo.numero,
                          expression: "arquivo.numero"
                        }
                      ],
                      staticClass: "form-control string optional",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "arquivo[numero]",
                        id: "arquivo_numero"
                      },
                      domProps: { value: _vm.arquivo.numero },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.arquivo, "numero", $event.target.value)
                        }
                      }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.options.upload_file
            ? _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group datepicker optional arquivo_emissao"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label datepicker optional",
                        attrs: { for: "arquivo_emissao" }
                      },
                      [_vm._v("Emissão")]
                    ),
                    _vm._v(" "),
                    _c("cmp-datepicker", {
                      attrs: { id: "arquivo_emissao" },
                      model: {
                        value: _vm.arquivo.emissao,
                        callback: function($$v) {
                          _vm.$set(_vm.arquivo, "emissao", $$v)
                        },
                        expression: "arquivo.emissao"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.options.upload_file
            ? _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group datepicker optional arquivo_validade"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label datepicker optional",
                        attrs: { for: "arquivo_validade" }
                      },
                      [_vm._v("Validade")]
                    ),
                    _vm._v(" "),
                    _c("cmp-datepicker", {
                      attrs: { id: "arquivo_validade" },
                      model: {
                        value: _vm.arquivo.validade,
                        callback: function($$v) {
                          _vm.$set(_vm.arquivo, "validade", $$v)
                        },
                        expression: "arquivo.validade"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.options.upload_file
            ? _c("div", { staticClass: "col-sm-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group string optional arquivo_dias_para_renovacao"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label string optional",
                        attrs: { for: "arquivo_dias_para_renovacao" }
                      },
                      [_vm._v("Dias para renovação")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.arquivo.dias_para_renovacao,
                          expression: "arquivo.dias_para_renovacao"
                        }
                      ],
                      staticClass: "form-control string optional",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "arquivo[dias_para_renovacao]",
                        id: "arquivo_numero"
                      },
                      domProps: { value: _vm.arquivo.dias_para_renovacao },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.arquivo,
                            "dias_para_renovacao",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.options.upload_file
            ? _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mostrarOpcaoMultiplos,
                          expression: "mostrarOpcaoMultiplos"
                        }
                      ],
                      staticClass: "col-sm-3"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-sm-12 checkbox checkbox-primary" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.uploads.multiplos,
                                expression: "uploads.multiplos"
                              }
                            ],
                            attrs: {
                              id: "checkbox2",
                              type: "checkbox",
                              checked: ""
                            },
                            domProps: {
                              checked: Array.isArray(_vm.uploads.multiplos)
                                ? _vm._i(_vm.uploads.multiplos, null) > -1
                                : _vm.uploads.multiplos
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.uploads.multiplos,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.uploads,
                                        "multiplos",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.uploads,
                                        "multiplos",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.uploads, "multiplos", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "checkbox2" } }, [
                            _vm._v("Permitir múltiplos")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.uploads.multiplos,
                              expression: "uploads.multiplos"
                            }
                          ],
                          staticClass: "col-sm-12"
                        },
                        [
                          _c("div", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.uploads.agrupar,
                                  expression: "uploads.agrupar"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "radio1",
                                id: "radio1"
                              },
                              domProps: {
                                value: true,
                                checked: _vm._q(_vm.uploads.agrupar, true)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(_vm.uploads, "agrupar", true)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "radio1" } }, [
                              _vm._v("Agrupar documentos no mesmo anexo")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.uploads.agrupar,
                                  expression: "uploads.agrupar"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "radio1",
                                id: "radio1"
                              },
                              domProps: {
                                value: false,
                                checked: _vm._q(_vm.uploads.agrupar, false)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(_vm.uploads, "agrupar", false)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "radio1" } }, [
                              _vm._v("Criar um anexo para cada documento")
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("vue-dropzone", {
                    ref: "myVueDropzone",
                    class: _vm.mostrarOpcaoMultiplos ? "col-sm-9" : "",
                    attrs: {
                      id: "dropzone",
                      options: _vm.dzOptions(),
                      language: _vm.langOptions
                    },
                    on: {
                      "vdropzone-sending": _vm.addParams,
                      "vdropzone-success": _vm.oneSucess,
                      "vdropzone-success-multiple": _vm.multiSucess
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "pull-right" }, [
        _c("div", { staticClass: "btn-group" }, [
          _vm.action == "new" && _vm.isNovoAnexo()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-w-m btn-primary active m-r-5",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.saveAnexo("+1")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.saveAnexoBtnCaption() + " +1") +
                      "\n          "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.action == "new" || _vm.action == "edit"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-w-m btn-primary active m-r-5",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.saveAnexo()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.saveAnexoBtnCaption()) +
                      "\n          "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-w-m btn-default",
              attrs: { type: "button", "data-dismiss": "modal" }
            },
            [_vm._v("Fechar")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }