<template>
  <div class="modal-content">
    <div class="modal-header" id="modal-header">
      <form-cab :header="options.form_header"></form-cab>
    </div class="modal-header">
    <div class="modal-body row" id="modal-body">
      <form novalidate="novalidate" class="simple_form edit_anexo"
            id="edit_anexo">
<!--
        <div class="col-sm-12">
          <h3>Anexo</h3>
        </div>
 -->
        <div class="col-sm-12">
          <div class="form-group string optional arquivo_title">
            <label for="arquivo_title" class="control-label string optional">
              Descrição
            </label>
            <input type="text" name="arquivo[title]" id="arquivo_title"
                   class="form-control string optional"
                   v-model="anexo.descricao">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group string optional arquivo_title">
            <label for="arquivo_title" class="control-label string optional">
              Responsável
            </label>

            <cmp-v-select
              input_class="form-control select select2"
              :options="options.responsaveis_col"
              :required="false"
              :multiple="false"
              :value="anexo.responsavel_id"
              v-model="anexo.responsavel_id">
            </cmp-v-select>

          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group text optional arquivo_local_arquivo_id">
            <label for="arquivo_local_arquivo_id" class="control-label text optional">Arquivado em</label>
            <cmp-v-select
                    input_class="form-control select select2"
                    :options="options.local_arquivos"
                    :required="false"
                    :multiple="false"
                    :value="arquivo.local_arquivo_id"
                    v-model="arquivo.local_arquivo_id">
            </cmp-v-select>
          </div>
        </div>


        <div v-if="options.upload_file"
             class="col-sm-12">
          <h3>Documento</h3>
        </div>

        <div v-if="options.upload_file"
             class="col-sm-3">
          <div class="form-group string optional arquivo_numero">
            <label for="arquivo_numero" class="control-label string optional">Numero</label>
            <input type="text" value="" name="arquivo[numero]"
                   id="arquivo_numero" class="form-control string optional"
                   v-model="arquivo.numero">
          </div>
        </div>
        <div v-if="options.upload_file"
             class="col-sm-3">
          <div class="form-group datepicker optional arquivo_emissao">
            <label for="arquivo_emissao" class="control-label datepicker optional">Emissão</label>

            <cmp-datepicker
              id="arquivo_emissao"
              v-model="arquivo.emissao">
            </cmp-datepicker>
          </div>
        </div>
        <div v-if="options.upload_file"
             class="col-sm-3">
          <div class="form-group datepicker optional arquivo_validade">
            <label for="arquivo_validade" class="control-label datepicker optional">Validade</label>

            <cmp-datepicker
              id="arquivo_validade"
              v-model="arquivo.validade">
            </cmp-datepicker>
          </div>
        </div>
        <div v-if="options.upload_file"
             class="col-sm-3">
          <div class="form-group string optional arquivo_dias_para_renovacao">
            <label for="arquivo_dias_para_renovacao" class="control-label string optional">Dias para renovação</label>
            <input type="text" value="" name="arquivo[dias_para_renovacao]"
                   id="arquivo_numero" class="form-control string optional"
                   v-model="arquivo.dias_para_renovacao">
          </div>
        </div>




        <div v-if="options.upload_file"
             class="col-sm-12">
          <div v-show="mostrarOpcaoMultiplos"
               class="col-sm-3">
            <div class="col-sm-12 checkbox checkbox-primary">
              <input id="checkbox2"
                     type="checkbox"
                     v-model="uploads.multiplos"
                     checked="">
              <label for="checkbox2">Permitir múltiplos</label>
            </div>

            <div v-show="uploads.multiplos"
                 class="col-sm-12">
              <div class="radio">
                <input type="radio" name="radio1" id="radio1"
                       v-model="uploads.agrupar"
                       :value="true">
                <label for="radio1">Agrupar documentos no mesmo anexo</label>
              </div>
              <div class="radio">
                <input type="radio" name="radio1" id="radio1"
                       v-model="uploads.agrupar"
                       :value="false">
                <label for="radio1">Criar um anexo para cada documento</label>
              </div>
            </div>
          </div>
          <vue-dropzone :class="mostrarOpcaoMultiplos ? 'col-sm-9' : ''"
                        ref="myVueDropzone"
                        id="dropzone"
                        :options="dzOptions()"
                        :language="langOptions"
                        v-on:vdropzone-sending="addParams"
                        v-on:vdropzone-success="oneSucess"
                        v-on:vdropzone-success-multiple="multiSucess"
          >
          </vue-dropzone>
        </div>
      </form>

    </div class="modal-body">

    <div class="modal-footer">
      <div class="pull-right">
        <div class="btn-group">
          <button v-if="((action == 'new') && isNovoAnexo())"
                  type="button"
                  class="btn btn-w-m btn-primary active m-r-5"
                  @click="saveAnexo('+1')">
            {{ saveAnexoBtnCaption() + ' +1' }}
          </button>
          <button v-if="((action == 'new') || (action == 'edit'))"
                  type="button"
                  class="btn btn-w-m btn-primary active m-r-5"
                  @click="saveAnexo()">
            {{ saveAnexoBtnCaption() }}
          </button>
          <a type="button"
             class="btn btn-w-m btn-default"
             data-dismiss="modal"
          >Fechar</a>
        </div>
      </div>
    </div class="modal-footer">

  </div>
</template>

<script>

  import inputUtils     from 'packs/components/mixins/inputUtils.vue'
  import FormCab        from 'packs/components/form/form-cab.vue'
  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'

  import vue2Dropzone from 'vue2-dropzone' ;
  // import 'vue2-dropzone/dist/vue2Dropzone.css' ;

  export default {
    name: 'UploadArquivo',
    mixins: [inputUtils],
    components: {
      'form-cab': FormCab,
      'cmp-datepicker': CompDatePicker,
      'cmp-v-select'  : CmpVSelect,
      'vue-dropzone': vue2Dropzone
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'upload_arquivo',
        name: 'UploadArquivo',
        opts: this.options,
        anexo: this.options.anexo,
        arquivo: this.options.anexo.arquivo,

        action: this.options.action,

        token: this.options.token,

        uploads: {
          multiplos: false,
          agrupar: true
        },

        criar_nova_versao: true

        // langOptions: {
        //   dictDefaultMessage: "Novo Texto"
        // }
      }
    },
    mounted: function() {
      this.anexo = this.options.anexo ;
      this.options.ja_upados.forEach((upado, index) => {
        this.$refs.myVueDropzone.manuallyAddFile({
          name: upado.filename,
          size: upado.size
        })
      });
    },
    methods: {
      isNovoAnexo: function () {
        return !(this.anexo.id && (this.anexo.id > 0))
      },
      saveAnexoBtnCaption: function () {
        if (this.isNovoAnexo()) {
          return 'Criar Pasta'
        } else {
          return 'Atualizar Pasta'
        }
      },
      anexoUrl: function() {
        if (this.isNovoAnexo()) {
          return this.options.anexos_url
        } else {
          return this.options.anexos_url + `/${this.anexo.id}`
        }
      },
      saveAnexo: function(maisUm) {
        var that = this;
        $.ajax({
          method: (this.isNovoAnexo() ? 'post' : 'put'),
          data: {
            anexo: {
              anexable_id:   that.anexo.anexable_id,
              anexable_type: that.anexo.anexable_type,
              responsavel_id: that.anexo.responsavel_id,
              descricao: that.anexo.descricao,

              emissao: that.arquivo.emissao,
              validade: that.arquivo.validade,
              numero: that.arquivo.numero,
              local_arquivo_id: that.arquivo.local_arquivo_id,
              dias_para_renovacao: that.arquivo.dias_para_renovacao
            },
            save_plus: (maisUm || null)
          },
          url: that.anexoUrl() + '.json',
          success: function(res) {
            that.anexo = res.record ;
            window.vm.alert('success', (res.msg || 'Anexo atualizado com sucesso!')) ;
            window.vm.refresh(that.opts.target_refresh)
            if (!maisUm) {$('#modal-main').modal('hide')}
          },
          error: function(res) {
            window.vm.alert('warning', 'Erro ao tentar atualizar anexo!') ;
          }
        });
      },
      addParams: function (file, xhr, formData) {
        formData.append( 'anexo_id', (this.anexo.id || 0) )
        formData.append( 'anexable_id', (this.anexo.anexable_id || 0) )
        formData.append( 'anexable_type', (this.anexo.anexable_type || '') )
        formData.append( 'responsavel_id', (this.anexo.responsavel_id || '') )
        formData.append( 'descricao', (this.anexo.descricao || '') )

        formData.append( 'numero',  (this.arquivo.numero  || '') )
        formData.append( 'emissao',  (this.arquivo.emissao  || '') )
        formData.append( 'validade', (this.arquivo.validade || '') )
        formData.append( 'local_arquivo_id', (this.arquivo.local_arquivo_id || '') )
        formData.append( 'dias_para_renovacao', (this.arquivo.dias_para_renovacao || '') )

        formData.append('authenticity_token', this.token);
      },
      arquivo2Create: function() {
        return {
          anexo_id: this.anexo.id,
          emissao: this.anexo.emissao,
          validade: this.anexo.validade,
          responsavel_id: this.anexo.responsavel_id
        }
      },
      multiSucess: function(par1, par2) {
        window.vm.alert('success', 'Documentos carregados com sucesso!') ;
        window.vm.refresh(this.options.target_refresh)
      },
      oneSucess: function(par1, par2) {
        // So atualizamos o id do anexo para agrupar.
        // Se for para Criar um anexo a cada doc, continuamos amndando com id 0
        if (par2.record.anexo_id && this.uploads.agrupar) {
          this.anexo.id = par2.record.anexo_id ;
        }
        this.$refs.myVueDropzone.dropzone.autoProcessQueue = true;

        window.vm.alert('success', (par2.msg || 'Documento carregado com sucesso!')) ;
        window.vm.refresh(this.options.target_refresh)
      },
      errorInspect: function (pa1, pa2, pa3) {
        // body...
      },
      dropzone: function () {
        this.$refs.myVueDropzone.dropzone
      },
      refresh_table: function() {
        window.vm.refresh(this.options.target_refresh)
      },
      dzOptions: function() {
        var opts = {
          url: this.options.arquivos_url,
//          maxFilesize: 500,
          dictDefaultMessage: 'Clique ou arraste e solte os arquivos aqui',
          dictFileTooBig: 'Documento de {{filesize}} excede o limite de {{maxFilesize}}',
          dictMaxFilesExceeded: 'Marque -Permitir multiplos documentos- se for necessário ',
//          uploadMultiple: this.uploads.multiplos,
 //         autoProcessQueue: this.autoProcessQueue,
          maxFiles: 80,
          timeout: null,
          //maxFiles: this.maxFilesToUp,
          parallelUploads: 1 // this.parallelUploadsFiles
        }
        return opts
      }
    },
    computed: {
      maxFilesToUp: function () {
        if (this.uploads.multiplos) {
          return null
        } else {
          return this.options.ja_upados.length + 1
        }
      },
      parallelUploadsFiles: function () {
        if (this.uploads.agrupar && this.options.action == 'new') {
          // Para agrupar precisamos esperar o id do anexo
          return 1
        } else {
          return null   // o default
        }
      },
      autoProcessQueue: function () {
        if (this.uploads.agrupar && this.options.action == 'new') {
          return false
        } else {
          return null   // o default
        }
      },
      mostrarOpcaoMultiplos: function () {
        return (this.options.action == 'new')
      }
    }

  };
</script>
