var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.klass },
    [
      _c("cmp-v-select", {
        attrs: {
          name: "participante[lista]",
          multiple: true,
          options: _vm.funcionarios,
          value: _vm.particips
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }