<template>
  <div id="formulario_fields"
       class="col-sm-12">
    <group-render v-for="(group, index) in groupsCollection"
      :key="index"
      :group_opts="groupGroupOpts(group, index)"
      :options="groupOptions"
      :model="options.model"
      :errors="options.errors"
      class="list-group">
    </group-render>
  </div>
</template>

<script>
  import DataStore      from 'packs/vuex/stores/form_data_store.js'
  import GroupRender    from "packs/components/form/group_render.vue";

  export default {
    name: 'FormularioFields',
    mixins: [DataStore],
    components: {
      'group-render' :  GroupRender
    },
    // model é o formulario.rb
    props: ['options'],
    data: function () {
      return {

        groups_list: [],

        eleEditor : null,
        eleRender : null,

        fbEditor : null,
        fbRender : null

      }
    },
    mounted: function () {
      this.$set(this, 'groups_list', this.options.schema.groups)

      this.initStore(this.options.storename)
      var that = this
      that.loadStore({
        formulario: that.options.formulario
      })
    },
    methods: {
      groupGroupOpts: function(group, index) {
        return {
          group: group,
          index: index
        }
      },
      saveForm: function() {
        return 'x'
      }
    },
    computed: {
      groupsCollection() {
        // return this.$store.getters['FormularioStore/groupsCollection']
        return this.groups_list ;
      },
      groupOptions: function() {
        return {
          model_name: this.options.model_name,
          storename: this.options.storename,
          model_url: this.options.model_url,
          hide_panel: true
        }
      }
    },
    watch: {
    }
  };
</script>

