<template>
  <div :class="klass">

    <div v-if="options.tipo == 'cronograma_equipe'"
         class="col-sm-12 p-b-10">
      <p>
        <span><strong>Estrutura da planilha (colunas)</strong></span>
        <div class="col-sm-12">
          <span class="col-sm-5 col-md-offset-1">Coluna 1: Nome do serviço</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 5: Identificador do local </span>
          <span class="col-sm-5 col-md-offset-1">Coluna 2: Código da composição (serviço)</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 6: Nome da equipe</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 3: Data de inicio </span>
          <span class="col-sm-5 col-md-offset-1">Coluna 7: Unidade do canteiro</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 4: Data fim</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 8: Quantidade equivalente</span>
        </div>
      </p>
    </div>

    <div v-if="options.tipo == 'fvs_plan'"
         class="col-sm-12 p-b-10">
      <p>
        <span><strong>Estrutura da planilha (colunas)</strong></span>
        <div class="col-sm-12">
          <span class="col-sm-5 col-md-offset-1">Coluna 1: Nome do serviço (FVS)</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 4: Fim</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 2: Local</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 5: Status</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 3: Inicio </span>
        </div>
      </p>
    </div>

    <div v-if="options.tipo == 'rns_plan'"
         class="col-sm-12 p-b-10">
      <p>
        <span><strong>Célula A1 deve conter o código da RN</strong></span>
        <br>
        <span>
          <strong>
            {{ `TRs devem começar na ${options.linha1}ª linha` }}
          </strong>
        </span>
        <br>
        <span><strong>Estrutura da planilha (colunas)</strong></span>
        <div class="col-sm-12">
          <span v-for="(col, index) in options.colunas"
                class="col-sm-5 col-md-offset-1">
            {{ `Coluna ${col.posicao}: ${col.nome}` }}
          </span>
<!--
          <span class="col-sm-5 col-md-offset-1">Coluna 7: Transação</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 2: CR</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 8: Data Emissão</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 3: Fornecedor</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 9: Valor</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 4: Item PCG</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 10: Data Vencimento</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 5: Observação</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 11: Data Prevista</span>
          <span class="col-sm-5 col-md-offset-1">Coluna 6: Documento</span>
 -->
        </div>
      </p>
    </div>

    <div v-if="options.title_label" class="col-sm-12">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          {{ options.title_label }}
        </label>
        <input type="text"
               class="form-control string optional"
               readonly
               :value="options.title_value">
      </div>
    </div>

    <div v-if="options.tipo == 'orca'" class="col-sm-5">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          Obra para atualização de orçamento
        </label>
        <cmp-v-select
          :required="true"
          :options="options.obras"
          :returnObj='true'
          v-model="selected_obra">
        </cmp-v-select>
      </div>
    </div>

    <div v-if="options.tipo == 'orca'" class="col-sm-5">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          Código Solum da obra já existente
        </label>
        <input type="text"
               class="form-control string optional"
               v-model="opts.solum_old">
      </div>
    </div>

    <div v-if="options.tipo == 'orca'" class="col-sm-5">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          Código Solum do orçamento atualizado
        </label>
        <input type="text"
               class="form-control string optional"
               v-model="opts.solum_new">
      </div>
    </div>

    <div v-if="options.tipo == 'rns_plan'" class="col-sm-5">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          Negócio
        </label>
        <cmp-v-select
          :required="true"
          :options="options.obras"
          :returnObj='true'
          v-model="selected_obra">
        </cmp-v-select>

      </div>
    </div>
    <div v-if="options.tipo == 'rns_plan'" class="col-sm-5">
      <div class="form-group string optional arquivo_responsavel">
        <label for="arquivo_responsavel" class="control-label string optional">
          Semana
        </label>
        <cmp-v-select
          :required="true"
          :options="options.semanas"
          :returnObj='true'
          v-model="selected_semana"
          :key="refreshInc">
        </cmp-v-select>
      </div>
    </div>

    <div class="col-sm-12">
      <vue-dropzone ref="myVueDropzone"
                    id="dropzone"
                    :options="dzOptions"
                    :language="langOptions"
                    v-on:vdropzone-sending="addParams"
                    v-on:vdropzone-params="params"
                    v-on:vdropzone-success="successUp"
                    v-on:vdropzone-error="errorUp"

                    dictDefaultMessage="Novo Texto">
      </vue-dropzone>
    </div>

    <div v-if="errors.length > 0" class="col-sm-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Errors no arquivo enviado</h5>
        </div>
        <div class="ibox-content">
          <ul class="todo-list m-t error small-list">
            <li v-for="error_text in errors" style="color: red;">
              <span class="has-error">
                {{ error_text }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="importados.length > 0" class="col-sm-12">
      <table  class="table table-inner table-vmiddle"
              id="table_horas">
        <thead>
          <tr>
            <th class="text-left" style="width: 40%">Arquivo Importado</th>
            <th class="text-left" style="width: 30%">Resultado</th>
            <th class="text-left" style="width: 30%">Resumo da importação</th>
          </tr>
        </thead>
        <tbody id="importados">
          <tr v-for="(impo, index) in importados"
              :key="index + 'impolin'"
          >
            <td class="p-t-0 p-b-0 text-left"
                :key="index + 'impocol1'"
            >{{ impo.filename }}</td>
            <td class="p-t-0 p-b-0 text-left"
                :key="index + 'impocol2'"
            >{{ impo.status }}</td>
            <td class="p-t-0 p-b-0 text-left"
                :key="index + 'impocol3'"
            >
              <a target="_blank"
                 :href="impo.url">
                 {{impo.caption}}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>

  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'
  import inputUtils     from './../mixins/inputUtils.vue'

  import vue2Dropzone from 'vue2-dropzone' ;
  // import 'vue2-dropzone/dist/vue2Dropzone.css' ;

  export default {
    name: 'UploadXML',
    mixins: [inputUtils],
    components: {
      'cmp-v-select'  : CmpVSelect,
      'vue-dropzone': vue2Dropzone
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'upload_arquivo',
        opts: this.options,

        errors: [],
        refreshInc: 100,

        selected_obra: null,
        selected_semana: null,

        importados: [],

        dzOptions: {
          url: this.options.url,
          uploadMultiple: false,
          language: {
            dictDefaultMessage: "Novo Texto"
          }
        },
        langOptions: {
          dictDefaultMessage: "Novo Texto"
        }
      }
    },
    mounted: function() {
      if (this.opts.tipo == 'rns_plan') {
        this.$set(this, 'selected_semana', this.options.selected_semana);
        this.refreshInc += 1 ;
      }
    },
    methods: {
      params: function() {
        return {
          param3: 'pepepe'
        }
      },
      addParams: function (file, xhr, formData) {
        formData.append( 'authenticity_token', this.opts.form_token);
        formData.append( 'user_id',     this.opts.user_id)
        formData.append( 'obra_id',     this.opts.obra_id)
        formData.append( 'equipe_id',   this.opts.equipe_id)
        formData.append( 'periodo_id',   this.opts.periodo_id)
        formData.append( 'map_id',      this.opts.map_id)
        formData.append( 'tipo',        this.opts.tipo)
        if (this.opts.tipo !== 'orca') {
          formData.append( 'obra_id',     this.opts.obra_id)
        } else {  // Orçamento
          formData.append( 'obra_id',     this.selected_obra.value)
          formData.append( 'solum_old',     this.opts.solum_old)
          formData.append( 'solum_new',     this.opts.solum_new)
        }
        if (this.opts.tipo == 'rns_plan') {
          formData.append( 'obra_id',     this.selected_obra.value)
          formData.append( 'semana_id',   this.selected_semana.value)
        }
      },
      successUp: function(file, res) {
        debugger
        var flash = 'success';
        if (res.result && res.result.flash) { flash = res.result.flash }
        this.importados.push(res.result) ;
        vm.alert(flash, res.msg) ;
        this.errors = []
      },
      errorUp: function(file, res, xhr) {
        this.errors = res.errors
        vm.alert('warning', res.msg) ;
      },

      refresh_table: function() {
        var that = this;
        $.ajax({
          method: 'GET',
          url: that.options.url + '/refresh.js'
        });
      }
    },
    computed: {
    }

  };
</script>
