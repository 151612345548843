<template>
  <div class="form-group date select required"
       :class="extraReqWidthClass(field, 'has-error')">
    <label class="control-label select required"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           title=""
           :data-original-title="field.helptext">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>
    <cmp-v-select v-if="value"
            input_class="form-control select select2"
            :options="options2Select()"
            :required="field.required"
            :multiple="field.multiple"
            :name="controlName(field.name)"
            :value="value"
            :id="controlId(field.name)">
    </cmp-v-select>
    <cmp-v-select v-else
            input_class="form-control select select2"
            :options="options2Select()"
            :required="field.required"
            :multiple="field.multiple"
            :name="controlName(field.name)"
            v-model="compFieldValue"
            :id="controlId(field.name)">
    </cmp-v-select>
    <span class="help-block">{{ error }}</span>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'
  import CmpVSelect from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'RenderSelect',
    mixins: [renderUtils],
    components: {
      'cmp-v-select'           : CmpVSelect
    },
    props: ['field', 'model_name', 'value', 'error', 'store_name'],
    data: function () {
      return {
        klass: 'ed-field-input'
      }
    },

    methods: {
      options2Select: function() {
        if ((this.field.specialSource != '') &&
            (this.collections)) {
          var coll = this.collections[this.field.specialSource];
          return coll;
        } else {
          return this.field.values ;
        }
      }
    },
    computed: {
      collections: function() {
        return this.$store.getters[this.store_name + '/collections']
      }
    }
  };
</script>

<style scoped>
</style>
