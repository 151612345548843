var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "doar_horas_temp" } }, [
    _c(
      "form",
      {
        staticClass: "simple_form doar-horas",
        attrs: {
          id: "doar-horas-form-id",
          novalidate: "novalidate",
          action: _vm.url,
          "data-remote": "true",
          method: "post",
          "accept-charset": "UTF-8"
        }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
          _vm._v(" "),
          _c("input", {
            attrs: {
              name: "authenticity_token",
              type: "hidden",
              id: "authenticity_token"
            }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "tarefa_id" },
            domProps: { value: _vm.tarefa_id }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "table",
              {
                staticClass: "table table-inner table-vmiddle",
                attrs: { id: "table_horas" }
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  { attrs: { id: "membros" } },
                  _vm._l(_vm.horas, function(hora, index) {
                    return hora.pode_doar == 1
                      ? _c("tr", [
                          _c("td", { staticClass: "p-t-0 p-b-0 text-left" }, [
                            _c("input", {
                              attrs: {
                                type: "hidden",
                                name: _vm.controlName("doar[][id]", index)
                              },
                              domProps: { value: hora.id }
                            }),
                            _vm._v(
                              "\n                  " +
                                _vm._s(hora.nome) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "p-t-0 p-b-0 text-left" }, [
                            _vm._v(_vm._s(hora.unidade))
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                            _vm._v(_vm._s(_vm.number2strBr(hora.quantidade, 4)))
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "p-t-0 p-b-0 text-right" },
                            [
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: _vm.controlName("doar[][doar]", index)
                                },
                                domProps: { value: _vm.horas[index].doar }
                              }),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: _vm.controlName(
                                    "doar[][insumo_id]",
                                    index
                                  )
                                },
                                domProps: { value: _vm.horas[index].insumo_id }
                              }),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: _vm.controlName("doar[][saldo]", index)
                                },
                                domProps: { value: _vm.saldoHorasNum(index) }
                              }),
                              _vm._v(" "),
                              _c("vue-numeric", {
                                staticClass: "form-control horas",
                                attrs: {
                                  separator: ".",
                                  precision: 4,
                                  value: _vm.horas[index].doar,
                                  min: 0.0,
                                  max: hora.quantidade,
                                  "on:change": _vm.alocaDoacao(),
                                  id: "horas" + hora.id
                                },
                                model: {
                                  value: _vm.horas[index].doar,
                                  callback: function($$v) {
                                    _vm.$set(_vm.horas[index], "doar", $$v)
                                  },
                                  expression: "horas[index].doar"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                            _vm._v(_vm._s(_vm.saldoHora(index)))
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass: "table table-inner table-vmiddle",
                attrs: { id: "table_resultado" }
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "tbody",
                  { attrs: { id: "membros" } },
                  _vm._l(_vm.cmps, function(hora, index) {
                    return hora.pode_doar == 0 ||
                      _vm.saldoCmpsHoraNum(index) > 0
                      ? _c("tr", [
                          _c("td", { staticClass: "p-t-0 p-b-0 text-left" }, [
                            _c("input", {
                              attrs: {
                                type: "hidden",
                                name: _vm.controlName("manter[][id]", index)
                              },
                              domProps: { value: hora.id }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: {
                                type: "hidden",
                                name: _vm.controlName("manter[][doar]", index)
                              },
                              domProps: { value: _vm.cmps[index].doar }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: {
                                type: "hidden",
                                name: _vm.controlName("manter[][manter]", index)
                              },
                              domProps: { value: _vm.saldoCmpsHoraNum(index) }
                            }),
                            _vm._v(
                              "\n\n                  " +
                                _vm._s(hora.nome) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "p-t-0 p-b-0 text-left" }, [
                            _vm._v(_vm._s(hora.unidade))
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                            _vm._v(_vm._s(_vm.saldoCmpsHora(index)))
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _vm._m(4)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Doar horas/valores para o banco")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "50%" } }, [
          _vm._v("Nome")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "5%" } }, [
          _vm._v("Unidade")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Disponivel")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Doar")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Saldo")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-7" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Após doação")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "50%" } }, [
          _vm._v("Nome")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "5%" } }, [
          _vm._v("Unidade")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Saldo")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "btn text-left",
            attrs: { "data-dismiss": "modal", href: "#" }
          },
          [_vm._v("Fechar")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { name: "doit", type: "submit" }
          },
          [_vm._v("Confirmar a doação\n          ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }