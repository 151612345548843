
import modal_store       from 'packs/vuex/modal_store.js';

import AutoNumeric from 'autonumeric';

import "./../../stylesheets/modal_app.scss"
import "./../../stylesheets/vue2Dropzone.css"
import 'vue-datetime/dist/vue-datetime.css'
import "vue-multiselect/dist/vue-multiselect.min.css"

import CmpAutonumeric from 'packs/components/comp/cmp-autonumeric.vue';
import CmpCheckBox    from 'packs/components/comp/cmp-checkbox.vue';
import CmpDatePicker  from 'packs/components/comp/cmp-datepicker.vue';
import CmpDateTime    from 'packs/components/comp/cmp-datetime.vue';
import CmpDropZone    from 'packs/components/comp/cmp-dropzone.vue';
import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue';
import FormCab        from 'packs/components/form/form-cab.vue';

import { Datetime } from 'vue-datetime';

import UserSelect           from 'packs/components/user/users_select.vue';
import TreinoTurmaDatasForm from 'packs/components/treinamento/turma_datas_form.vue';

import ReuniaoParticip      from 'packs/components/reuniao/participantes_select.vue';
import TreinaParticip       from 'packs/components/treinamento/participantes_select.vue';
import ArquivoUpload        from 'packs/components/arquivo/upload_arquivo.vue';
import ImportaUpload        from 'packs/components/importacao/upload_foto.vue';
import ArquivoUploadVers    from 'packs/components/arquivo/upload_versao.vue';
import ImportaUpXAMLrm      from 'packs/components/importacao/upload_xml.vue';
import FormCrResp           from 'packs/components/form/cr_resposta_form.vue';

import EditModalMapa        from 'packs/components/mapa/vue-edit-modal-mapa.vue';
import HistoryBody          from 'packs/components/index_history.vue';
import FormPlanoCausa       from 'packs/components/causas/form_plano_causa.vue';

import FileSolicitacao      from 'packs/components/form/render_fields/render_file_solicitacao.vue';
import ArqPesquisa          from 'packs/components/arquivo/pesquisa_para_caixa.vue';
import AcaoRepeatOptions    from 'packs/components/acao/repeat-options.vue';
import DoarHoras            from 'packs/components/banco_horas/doar_horas.vue';
import ReceberHoras         from 'packs/components/banco_horas/receber_horas.vue';
import FormularioFields     from 'packs/components/form/formulario_fields.vue';

  import RenderTextText          from "packs/components/form/render_fields/render_text_text.vue";

import EqipeFormNovoM       from 'packs/components/equipe/form_novo_membro.vue';

import VueJsonPretty        from 'vue-json-pretty'

const ModalVm = {
  name: 'ModalApp',

  components: {
    'cmp-autonumeric' : CmpAutonumeric,
    'cmp-checkbox'    : CmpCheckBox,
    'cmp-datepicker'  : CmpDatePicker,
    'cmp-datetime'    : CmpDateTime,
    'cmp-dropzone'    : CmpDropZone,
    'cmp-v-select'    : CmpVSelect,
    'form-cab'        : FormCab,
    'datetime'        : Datetime,

    'users-select'            : UserSelect,
    'treino-turma-datas-form' : TreinoTurmaDatasForm,

    'reuniao-participantes-select'      : ReuniaoParticip,
    'treinamento-participantes-select'  : TreinaParticip,
    'upload-arquivo'                    : ArquivoUpload,
    'upload-foto'                       : ImportaUpload,
    'upload-versao'                     : ArquivoUploadVers,
    'upload-xml'                        : ImportaUpXAMLrm,
    'cr-resposta-form'                  : FormCrResp,

    'vue-edit-modal-mapa'      : EditModalMapa,
    'history-body'             : HistoryBody,
    'form-plano-causa'         : FormPlanoCausa,
    'formulario-fields'        : FormularioFields,

      'render-text-text'          : RenderTextText,

      'vue-json-pretty'           : VueJsonPretty,

    'render-file-solicitacao'  : FileSolicitacao,
    'pesquisa-para-caixa'      : ArqPesquisa,
    'acao-repeat-options'      : AcaoRepeatOptions,
    'doar-horas'               : DoarHoras,
    'receber-horas'            : ReceberHoras,
    'form-novo-membro'         : EqipeFormNovoM
  },

  data: {
    name: 'NameModalApp',
    responsavel: null,
    selected_papel_id: 9,
    form: {}
  },
  created: function() {
    // this.initUserStore()
  },
  methods: {
    setSelected_papel_id: function(papel_id) {
      this.$set(this, 'selected_papel_id', papel_id);
    }
  }
}

export default ModalVm ;
