<template>
  <div class="pesquisa">
    <div class="row">
      <form novalidate="novalidate"
            class="simple_form docs-vencer">

        <div v-if="options.empresas.length > 0" class="col-sm-6">
          <div class="form-group string optional arquivo_title">
            <label for="arquivo_title"
                   class="control-label string optional">
              Empresa:
            </label>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refresh_empresa"
              :options="options.empresas"
              :required="false"
              :multiple="false"
              :value="empresa_id"
              v-model="empresa_id">
            </cmp-v-select>
          </div>
        </div>

        <div v-if="options.negocios.length > 0" class="col-sm-6">
          <div class="form-group string optional arquivo_title">
            <label for="arquivo_title" class="control-label string optional">Negocio:</label>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refresh_negocio"
              :options="options.negocios"
              :required="false"
              :multiple="false"
              :value="negocio_id"
              v-model="negocio_id">
            </cmp-v-select>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group string optional arquivo_title">
<!--
            <label for="arquivo_title" class="control-label string optional">Pesquisar
            </label>
 -->
            <input type="text" name="arquivo[title]" id="arquivo_title"
                   placeholder='Digite 3 letras para começar a pesquisar'
                   class="form-control string optional"
                   v-model="filter">
          </div>
        </div>

      </form>

      <table class="table table-hover"
             id="table_arquivos"
             :key="refresh_arquivos"
             ref="table_arquivos">
        <thead>
          <tr>
            <th style="width: 40%">Documento</th>
            <th style="width: 20%">Responsavel</th>
            <th style="width: 10%">Arquivado por</th>
            <th style="width: 10%">Arquivado</th>
            <th style="width: 10%">Validade</th>
            <th class="project-actions text-rigth"
                style="width: 10%">Incluir</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(arquivo, index) in arquivos">
            <td v-html="arquivo.title_link"></td>
            <td>{{ arquivo.responsavel }}</td>
            <td>{{ arquivo.user }}</td>
            <td>{{ arquivo.data }}</td>
            <td>{{ arquivo.validade }}</td>
            <td style="text-align: right">
              <button @click="addArquivo(arquivo.id, index)"
                      class="btn btn-primary btn-xs waves-effect"
              >{{ options.action_button_caption }}</button>

            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>

  import inputUtils     from 'packs/components/mixins/inputUtils.vue'
  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'PesquisaParaCaixa',
    mixins: [inputUtils],
    components: {
      'cmp-v-select'  : CmpVSelect
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'pesquisa_para_caixa',
        opts: this.options,

        mounted: false,
        refresh_empresa: 0,
        refresh_negocio: 0,
        refresh_arquivos: 0,

        empresa_id: 0,
        negocio_id: 0,
        filter: '',

        arquivos: [],

        token: this.options.token
      }
    },
    mounted: function() {
      this.mounted = true
    },
    watch: {
      empresa_id: function(new_empresa_id) {
        if ((new_empresa_id != 0) && (new_empresa_id != 999)) {
          this.negocio_id = 999
          this.refresh_negocio += 1
        }
        if (this.mounted) { this.atuaTable() }
      },
      negocio_id: function(new_negocio_id) {
        if ((new_negocio_id != 0) && (new_negocio_id != 999)) {
          this.empresa_id = 999
          this.refresh_empresa += 1
        }
        if (this.mounted) { this.atuaTable() }
      },
      filter: function(new_filter) {
        if (this.mounted) { this.atuaTable() }
      },
    },
    methods: {
      atuaTable: function() {
        if (this.filter.length < 3) {
          this.$set(this, 'arquivos', [])
          return
        }
        var that = this
        $.ajax({
          url: that.options.search_url + '.json',
          data: {
            empresa_id: that.empresa_id,
            negocio_id: that.negocio_id,
            exclude_local_id: that.options.caixa_id,
            q: that.filter
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            that.$set(that, 'arquivos', data.arquivos)
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })

      },
      addArquivo: function(arquivo_id, index) {
        var that = this
        var url_post = this.options.add_url.replace('arquivo_id', arquivo_id.toString())
        $.ajax({
          url: url_post + '.json',
          data: {
            movimentacao: {
              local_para_id: that.options.caixa_id,
              arquivo_id: arquivo_id
            }
          },
          type: 'post',
          format: 'json',
          success: function(data) {
            that.arquivos.splice(index, 1)
            that.refresh_arquivos += 1
            window.vm.refresh('tab-arquivos')
            window.vm.alert('success', data.msg)
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      }
    },
    computed: {
    }

  };
</script>
