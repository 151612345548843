<template>
  <div class="form-group text"
       :class="extraReqWidthClass(field, 'has-error')">
    <label class="control-label text"
           :class="field.required ? 'required' : 'optional'"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>
    <textarea v-if="value"
              class="form-control text"
              :class="field.required ? 'required' : 'optional'"
              :name="controlName(field.name)"
              :id="controlId(field.name)">{{ value }}</textarea>
    <textarea v-else
              class="form-control text"
              :class="field.required ? 'required' : 'optional'"
              v-model="compFieldValue"
              :name="controlName(field.name)"
              :id="controlId(field.name)">
    </textarea>
    <span class="help-block">{{ error }}</span>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'

  export default {
    name: 'RenderTextArea',
    mixins: [renderUtils],
    components: {
    },
    props: ['field', 'model_name', 'value', 'error', 'store_name'],
    data: function () {
      return {
        klass: 'ed-textarea'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
