var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group text",
      class: _vm.extraReqWidthClass(_vm.field, "has-error")
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label text",
          class: _vm.field.required ? "required" : "optional",
          attrs: {
            for: _vm.controlId(_vm.field.name),
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.field.description
          }
        },
        [
          _vm.field.required
            ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")
        ]
      ),
      _vm._v(" "),
      _vm.value
        ? _c(
            "textarea",
            {
              staticClass: "form-control text",
              class: _vm.field.required ? "required" : "optional",
              attrs: {
                name: _vm.controlName(_vm.field.name),
                id: _vm.controlId(_vm.field.name)
              }
            },
            [_vm._v(_vm._s(_vm.value))]
          )
        : _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.compFieldValue,
                expression: "compFieldValue"
              }
            ],
            staticClass: "form-control text",
            class: _vm.field.required ? "required" : "optional",
            attrs: {
              name: _vm.controlName(_vm.field.name),
              id: _vm.controlId(_vm.field.name)
            },
            domProps: { value: _vm.compFieldValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.compFieldValue = $event.target.value
              }
            }
          }),
      _vm._v(" "),
      _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }