<template>
  <div class="form-group numeric"
       :class="extraReqWidthClass(field, 'has-error')">
    <label class="control-label numeric"
           :class="field.required ? 'required' : 'optional'"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>
    <cmp-autonumeric v-if="value"
      :required="field.required"
      :name="controlName(field.name)"
      :value="Number(value)"
      :id="controlId(field.name)"
      :decimal_places="field.decimal_places"
      :min="Number(field.min)"
      :max="Number(field.max)"
    ></cmp-autonumeric>
    <cmp-autonumeric v-else
      :required="field.required"
      :name="controlName(field.name)"
      v-model="compFieldValue"
      :id="controlId(field.name)"
      :decimal_places="field.decimal_places"
      :min="field.min"
      :max="field.max"
    ></cmp-autonumeric>
    <span class="help-block">{{ error }}</span>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'
  import CmpAutoNumeric from 'packs/components/comp/cmp-autonumeric.vue'

  export default {
    name: 'RenderNumber',
    mixins: [renderUtils],
    components: {
      'cmp-autonumeric'  : CmpAutoNumeric
    },
    props: ['field', 'model_name', 'value', 'error', 'store_name'],
    data: function () {
      return {
        klass: 'ed-field-input'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
