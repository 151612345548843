import { render, staticRenderFns } from "./form_novo_membro.vue?vue&type=template&id=243a3d80&"
import script from "./form_novo_membro.vue?vue&type=script&lang=js&"
export * from "./form_novo_membro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/cp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('243a3d80')) {
      api.createRecord('243a3d80', component.options)
    } else {
      api.reload('243a3d80', component.options)
    }
    module.hot.accept("./form_novo_membro.vue?vue&type=template&id=243a3d80&", function () {
      api.rerender('243a3d80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/equipe/form_novo_membro.vue"
export default component.exports