var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group date datepicker",
      class: _vm.extraReqWidthClass(_vm.field)
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label date datepicker",
          class: _vm.field.required ? "required" : "optional",
          attrs: {
            for: _vm.controlId(_vm.field.name),
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.field.description
          }
        },
        [
          _vm.field.required
            ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")
        ]
      ),
      _vm._v(" "),
      _c("cmp-datetime", {
        staticClass: "form-control date datepicker",
        class: _vm.field.required ? "required" : "optional",
        attrs: {
          type: "datetime",
          name: _vm.controlName(_vm.field.name),
          id: _vm.controlId(_vm.field.name)
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }