var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row col-sm-12", class: _vm.klass },
    [
      _vm._l(_vm.model, function(index, field) {
        return _c("div", [
          _c("input", {
            attrs: {
              type: "hidden",
              name: _vm.controlName("[model][]", field)
            },
            domProps: { value: _vm.model[field] }
          })
        ])
      }),
      _vm._v("\n -->\n  ")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }