// index_history.vue
<template>
  <div id='index-history'>
    <div class="row"
         v-if="temHistorico">

      <div class="col-sm-8">
        <h4>Registro da alteração</h4>
        <div class="pmbb-view">
          <dl class="dl-horizontal">
            <dt>Quando</dt>
            <dd> {{ versao.created_at }} </dd>
            <dt>Operação</dt>
            <dd> {{ versao.action_name }} </dd>
            <dt>Autor</dt>
            <dd>
              <a v-bind:href="user_link">
                {{ versao.user.nome }}
              </a>
            </dd>
          </dl>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="popover-title">Mudanças</h4>
            <div class="content" id="diff-area">
              <div class="pre">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Atributo</th>
                      <th>Antes</th>
                      <th>Depois</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="at in changedkey">
                      <th> {{ translate_attr(at) }} </th>
                      <th> {{ object_to_str(versao.changeset[at][0]) }} </th>
                      <th> {{ object_to_str(versao.changeset[at][1]) }} </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="listview lv-user">
          <a v-for="ver in reverse"
             @click="selectVer(ver)">
            <div class="lv-item.media">
              <div class="lv-avatar bgm-orange pull-left"> {{ first_letter(ver) }} </div>
              <div class="media-body">
                <div class="lv-title"> {{ ver_action_name(ver) }} </div>
                <div class="lv-small"> {{ ver.created_at }} </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="row"
         v-else>
      <h4 class="pull-center">Sem registro de histórico</h4>
    </div>
  </div>
</template>

<script>


  export default {
    name: 'index_history',
    props: ['versiones'],
    data: function () {
      return {
        versao: {},
        black_list_att: [
         'id',
         'created_at',
         'updated_at',
         'batidas_editor_id'
        ]
      }
    },
    mounted: function () {
      if(this.versiones.length > 0) {
        this.versao = this.versiones[0];
      }
    },
    methods: {
      selectVer: function (ver) {
        this.versao = ver ;
      },
      ver_action_name: function(ver) {
        if (ver.action_name == 'Set classificar') {
          return 'Classificar data'
        } else if (ver.action_name == 'Create') {
          return 'Criação';
        } else if (ver.action_name == 'Set ponto') {
          return 'Edição de ponto';
        } else
        {
          return ver.action_name ;
        }
      },
      translate_attr: function(at) {
        if (at == 'aasm_state') {
          return 'status';
        } else {
          return at ;
        };
      },
      object_to_str: function(at) {
        if($.type(at) === "object") {
          return JSON.stringify(at);
        }
        if($.type(at) === "boolean") {
          return (at ? 'Sim' : 'Não');
        }
        return at;
      },
      first_letter: function(ver) {
        return ver.action_name.charAt(0) ;
      }

    },
    computed: {
      reverse: function() {
        return this.versiones.reverse();
      },
      temHistorico: function() {
        return ! $.isEmptyObject(this.versao);
      },
      user_link: function () {
        if (this.versao && this.versao.user)
          return '/users/' + this.versao.user.id.toString();
      },
      changedkey:  function () {
        var keys = [];
        for(var key in this.versao.changeset){
          if ((this.versao.changeset.hasOwnProperty(key)) &&
             (this.black_list_att.indexOf(key) < 0)) {
            keys.push(key);
          };
          //if (this.ver.changeset.hasOwnProperty(key)) {
          //  keys.push(key);
          //};
        };
        return keys;
      }
    }
  }
</script>
