// treinamento/turma_datas_form.vue
<template>
  <div class="col-sm-12">
    <h4>Datas para treinamento desta turma</h4>
    <div v-for="(data, index) in datas"
         :key="'treinadata-' + index">

      <div class=row></div>
      <div class="col-sm-4 form-group datepicker required turma_data">
        <label v-if="index == 0"
               for="turma_data"
               class="control-label datepicker required">
          <abbr title="obrigatorio">*
          </abbr> Data
        </label>
        <cmp-datepicker
            id="turma_data"
            :name="controlName('[datas][][data]', index)"
            :id="controlId('data', index)"
            min_date_desativada="hoje()"
            v-model="data.data">
        </cmp-datepicker>
      </div>

      <div class="col-sm-4 form-group text optional turma_carga_horaria">
        <label v-if="index == 0"
               for="turma_carga_horaria"
               class="control-label text optional">
          Carga horária
        </label>
        <cmp-autonumeric
          :required="true"
          :name="controlName('[datas][][carga_horaria]', index)"
          :id="controlId('carga_horaria', index)"
          :value="(data.carga_horaria || '0')"
          :decimal_places="2"
          :min="0"
          :max="24"
        ></cmp-autonumeric>
      </div>
      <div :class="(index == 0 ? 'p-t-20' : '')">
        <button class="btn btn-sm btn-danger"
                type="button"
                @click="removeData(index)">
          <i class="fa fa-trash fa-hc-fw m-r-5"></i>
          Remover data
        </button>
      </div>

    </div>
    <div class="row"></div>
    <div>
      <button class="btn btn-sm btn-primary"
              type="button"
              @click="appendData()">
        <i class="fa fa-plus fa-hc-fw m-r-5"></i>
        Adicionar data
      </button>
    </div>
  </div>
</template>

<script>

  import inputUtils     from 'packs/components/mixins/inputUtils.vue'
  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import cmpAutonumeric from 'packs/components/comp/cmp-autonumeric.vue'

  export default {
    name: 'TreinamentoTurmaDatasForm',
    mixins: [inputUtils],
    components: {
      'cmp-datepicker'  : CompDatePicker,
      'cmp-autonumeric' : cmpAutonumeric
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'TreinamentoTurmaDatasForm',
        model_name: 'turma',

        datas: this.options.datas
      }
    },
    methods: {
      removeData: function(index) {
        this.datas.splice(index, 1)
      },
      appendData: function() {
        this.datas.push({
          data: null,
          carga_horaria: null
        })

      },
      hoje: function(){
        return Date.now()
      }
    },
    computed: {
    }

  };
</script>
