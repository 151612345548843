// btn_novo_funcionario.vue
<template>
  <div class="col-sm-12">
    <div class="form-group funcao_id optional novo_membro_funcao_id">
      <label class="funcao_id optional" for="novo_membro_funcao_id">Função</label>

      <cmp-v-select
        input_class="form-control select select2"
        :options="this.lFuncoes2Sel"
        :required="true"
        :multiple="false"
        v-model="funcao_id">
      </cmp-v-select>
    </div>



    <div class="form-group funcionario_id optional novo_membro_funcionario_id">
      <label class="funcionario_id optional" for="novo_membro_funcionario_id">Funcionário</label>
      <cmp-v-select
        input_class="form-control select select2"
        :options="lFuncionarios2Sel"
        :required="true"
        :multiple="false"
        name="funcionario_id"
        v-model="funcionario_id">
      </cmp-v-select>
    </div>
  </div>
</template>

<script>

  import CmpVSelect from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'form_novo_funcionario',
    props: ['funcoes', 'funcionarios'],
    components: {
      'cmp-v-select'           : CmpVSelect
    },
    data: function () {
      return {
        l_funcoes: this.funcoes,
        l_funcionarios: this.funcionarios,
        funcao_id: -1,
        funcionario_id: 0,
        permanente: 0,
        sel_func: []
      }
    },
    mounted: function () {
    },
    computed: {
      lFuncoes2Sel: function () {
        return this.funcoes.map(function(item){
          return {
            value: item.id,
            label: item.nome
          }
        });
      },
      lFuncionarios2Sel: function () {
        var funcao_id = this.funcao_id
        if (funcao_id == -1) {return [];}
        return this.funcionarios[funcao_id].map(function(item){
          return {
            value: item.id,
            label: item.text
          }
        });
      }
    },

    methods: {
      alocaFuncionario: function () {
        // var funci_id = $('#sel_novo_funcionario').val()
        this.$parent.addNovoFuncionario({
          funci_id: this.funcionario_id,
          permanente: this.permanente
        });
        $("#modal-novo-funcionario").modal("hide");
      }
    }
  }

</script>

