<template>
  <div class="form-group header">
    <component :is="field.subtype">
      {{ field.label }}
    </component>
  </div>
</template>

<script>
  export default {
    name: 'RenderHeader',
    components: {
    },
    props: ['field', 'model_name'],
    data: function () {
      return {
        klass: 'rnd header'
      }
    },
    methods: {
    }
  };
</script>
