var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.klass },
    [
      _c("multiselect", {
        attrs: {
          multiple: true,
          taggable: true,
          selectedLabel: "Selecionado",
          selectLabel: "",
          deselectLabel: "Remover?",
          closeOnSelect: false,
          placeholder: "",
          options: _vm.users,
          "track-by": "value",
          label: "label"
        },
        on: { tag: _vm.addTag },
        model: {
          value: _vm.particips,
          callback: function($$v) {
            _vm.particips = $$v
          },
          expression: "particips"
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.particips, function(part, index) {
        return _c("div", [
          _c("input", {
            attrs: {
              type: "hidden",
              name: _vm.controlName("[lista][]", index)
            },
            domProps: { value: part.label }
          })
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }