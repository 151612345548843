<template>
  <div class="row col-sm-12"
      :class="klass">
<!--     <vue-form-generator :schema="schema"
                        :model="model"
                        :options="opts">
    </vue-form-generator>
 -->
    <div v-for="(index, field) in model">
      <input type="hidden"
             :name="controlName('[model][]', field)"
             :value="model[field]">

    </div>
 -->
  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  // import VueFormGenerator from "vue-form-generator";

  export default {
    name: 'CrRespostaForm',
    mixins: [inputUtils],
    components: {
      // 'vue-form-generator': VueFormGenerator.component
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-resposta-form',

        model:  this.options.model,
        schema: this.options.schema,
        opts:   this.options.options,

        model_name: this.options.model_name
      }
    },
    methods: {
    },
    watch: {
    }
  };
</script>

