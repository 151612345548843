
  const localStore = {
    namespaced: true,
    state: {
      formulario: {},
      header: {},
      model: {},
      model_name: '',
      create_url: '',
      storeLoaded: false
    },
    mutations: {
      load(state, data) {
        state.formulario   = data.formulario
        state.header       = data.header
        state.model        = (data.model || {})
        state.model_name   = data.model_name
        state.create_url   = data.create_url
        state.storeLoaded  = true
        return state;
      },
      setField(state, field) {
        state.model[field.name] = field.value
        return state;
      }
    },
    actions: {
      load(context, data) {
        context.commit('load', data)
      },
      setField(context, field) {
        context.commit('setField', field)
      }
    },
    getters: {
      fullForm: (state, getters, rootState, rootGetters) => {
        return (state.storeLoaded ? state : null)
      },
      model: (state, getters, rootState, rootGetters) => {
        return (state.storeLoaded ? state.model : null)
      },
      collections: (state, getters, rootState, rootGetters) => {
        return state.formulario.collections ;
      }
    }
  };

  export default {
    data: function() {
      return {
        storeName: '',
        storeEnabled: false,
        storeLoaded: false
      }
    },
    methods: {
      initStore: function(storeName, dataInit) {
        this.$set(this, 'storeName', storeName)
        const store = this.$store;

        // Carrega o módulo da store
        if (!(store && store.state && store.state[this.storeName])) {
          store.registerModule(this.storeName, localStore);
        } else {
          console.log(`reusing module: ${this.storeName}`);
        }

        this.$set(this.$data, 'storeEnabled', true)
        // Inicia com parametros da obra
        if (dataInit) {
          this.$store.dispatch( this.storeName + '/init', dataInit)
        }
      },
      loadStore: function(data) {
        this.$store.dispatch( this.storeName + '/load', data)
        this.$set(this.$data, 'storeLoaded', true)
      }
    },

    computed: {
      fullForm: function() {
        return this.$store.getters[this.storeName + '/fullForm']
      }
    }

  }
