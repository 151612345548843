var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group check_boxes boolean",
      class: _vm.extraReqWidthClass(_vm.field)
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label check_boxes boolean",
          class: _vm.field.required ? "required" : "optional",
          attrs: {
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.field.description
          }
        },
        [
          _vm.field.required
            ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.field.values, function(checkbox) {
        return _c("div", { staticClass: "form-check" }, [
          _c("input", {
            staticClass: "form-check-input radio-buttons",
            attrs: {
              type: "radio",
              id: _vm.controlId(_vm.field.name) + "_" + checkbox.value,
              name: _vm.controlName(_vm.field.name)
            },
            domProps: {
              value: checkbox.value,
              checked: checkbox.value == _vm.value ? "checked" : null
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: {
                for: _vm.controlId(_vm.field.name) + "_" + checkbox.value
              }
            },
            [_vm._v("\n      " + _vm._s(checkbox.label) + "\n    ")]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }