<template>
  <div class="form-group check_boxes boolean"
       :class="extraReqWidthClass(field)">
    <label class="control-label check_boxes boolean"
           :class="field.required ? 'required' : 'optional'"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>

    <div v-for="checkbox in field.values"
         class="form-check">

      <input class="form-check-input radio-buttons"
             type="radio"
             :id="controlId(field.name) + '_' + checkbox.value"
             :name="controlName(field.name)"
             :value="checkbox.value"
             :checked="((checkbox.value == value) ? 'checked' : null)">
      <label class="form-check-label"
             :for="controlId(field.name) + '_' + checkbox.value">
        {{ checkbox.label }}
      </label>
    </div>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'

  export default {
    name: 'RenderRadios',
    mixins: [renderUtils],
    components: {
    },
    props: ['field', 'model_name', 'value'],
    data: function () {
      return {
        klass: 'ed-checkbox'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
