<template>
  <div class="form-group date datepicker"
       :class="extraReqWidthClass(field, 'has-error')">
    <label class="control-label date datepicker"
           :class="field.required ? 'required' : 'optional'"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>
    <cmp-datepicker v-if="value"
           input_class="form-control date v-datepicker"
           :required="field.required"
           type="datepicker"
           :name="controlName(field.name)"
           :value="value"
           :id="controlId(field.name)">
    </cmp-datepicker>
    <cmp-datepicker v-else
           input_class="form-control date v-datepicker"
           :required="field.required"
           type="datepicker"
           :name="controlName(field.name)"
            v-model="compFieldValue"
           :id="controlId(field.name)">
    </cmp-datepicker>
    <span class="help-block">{{ error }}</span>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'
  import CmpDatepicker from 'packs/components/comp/cmp-datepicker.vue'

  export default {
    name: 'RenderDate',
    mixins: [renderUtils],
    components: {
      'cmp-datepicker'           : CmpDatepicker
    },
    props: ['field', 'model_name', 'value', 'error', 'store_name'],
    data: function () {
      return {
        klass: 'ed-field-input'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
