<template>
  <div class="form-group string"
       :class="extraReqWidthClass(field, 'has-error')">
    <label class="control-label string"
           :class="field.required ? 'required' : 'optional'"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}

      <input  style="position: absolute; top: -35400px;"
              class="form-control hidden"
              type="file"
              :ref="'file' + field.name"
              v-on:change="handleFileUpload('file' + field.name)"
              :id="controlId(field.name)">
    </label>
    <input    class="form-control"
              type="text"
              v-model="cfileName"
              v-on:click="addFile('file' + field.name)">

    <a class="btn"
       v-on:click="addFile('file' + field.name)">Selecionar arquivo</a>
    <span class="help-block">{{ error }}</span>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'
  import CmpDropZone from 'packs/components/comp/cmp-dropzone.vue'

  export default {
    name: 'RenderFileFile',
    mixins: [renderUtils],
    components: {
    },
    props: ['field', 'model_name', 'value', 'error', 'store_name', 'action_options'],
    data: function () {
      return {
        klass: 'ed-field-input',
        file:  '',
        filename: null
      }
    },
    computed: {
    },
    methods: {
      handleFileUpload: function(refName) {
        this.file = this.$refs[refName].files[0];
        this.submitFile();
      },
      addFile: function(refName){
        this.$refs[refName].click();
        return false ;
      },
      submitFile: function() {
        let that = this;
        let formData = new FormData();
        if (this.file) {
          formData.append('file', this.file);
        } else {
          formData.append('file', '');
        }
        formData.append('fieldname', this.field.name)
        formData.append('label', this.field.label)
        let submitPost = this.action_options.url + '/post_arquivos' + '.json' ;

        $.ajax({
          url: submitPost,
          data: formData,
          type: 'post',
          format: 'json',
          // contentType: 'multipart/form-data',
          contentType: false,
          processData: false,
          cache: false,
          success: function(data) {
            that.filename = that.$refs['file' + that.field.name].files[0].name
          },
          error: function(error) {
            console.log('ERRO!!');
          },
          complete: function(response) {
          }
        });
      }
    },
    computed: {
      cfileName: function() {
        return this.filename || this.value ;
      }
    }
  };
</script>

<style scoped>
</style>
