var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12", attrs: { id: "formulario_fields" } },
    _vm._l(_vm.groupsCollection, function(group, index) {
      return _c("group-render", {
        key: index,
        staticClass: "list-group",
        attrs: {
          group_opts: _vm.groupGroupOpts(group, index),
          options: _vm.groupOptions,
          model: _vm.options.model,
          errors: _vm.options.errors
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }