<template>
  <div :class="klass">
<!--
    <v-select v-if="opts.action == 'new'"
              v-model="selecteds"
              :options="users"
              :multiple="opts.multiple"
    >
    </v-select>
 -->
    <cmp-v-select v-if="opts.action == 'new'"
      input_class="form-control select select2"
      :options="users"
      :required="true"
      name="allocation[email]"
      v-model="selecteds"
    >
    </cmp-v-select>


    <div v-if="opts.action == 'edit'"
         class="form-group email optional alocacao_email">
      <label for="alocacao_email"
             class="control-label email optional">
        E-mail
      </label>
      <input autofocus="autofocus"
             type="email"
             id="alocacao_email"
             :value="selecteds"
             readonly
             class="form-control string email optional">
    </div>

  </div>
</template>

<script>

  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'
  import inputUtils     from 'packs/components/mixins/inputUtils.vue'

  export default {
    name: 'UsersSelect',
    mixins: [inputUtils],
    components: {
      'cmp-v-select'  : CmpVSelect
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'user_select',
        opts: this.options,
        model_name: this.options.model_name,
        users: this.options.users,
        selecteds: this.options.selecteds
      }
    },
    computed: {
    }

  };
</script>
