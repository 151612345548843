<template>
  <div :class="klass">
    <p v-html="field.label">
    </p>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'

  export default {
    name: 'RenderTextText',
    mixins: [renderUtils],
    components: {
    },
    props: ['field', 'model_name'],
    data: function () {
      return {
        klass: 'ed-field-paragraph'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
