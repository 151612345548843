var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.monted
    ? _c("div", { class: _vm.klass }, [
        _c(
          "div",
          { staticClass: "form-group text required plano_causa_causa_id" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("cmp-v-select", {
              attrs: {
                name: _vm.controlName("[causa_id]"),
                options: _vm.causas
              },
              model: {
                value: _vm.causa_id,
                callback: function($$v) {
                  _vm.causa_id = $$v
                },
                expression: "causa_id"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.causa_id > 0
          ? _c(
              "div",
              { staticClass: "sub-causas-list" },
              [
                _vm._l(_vm.comp_subcausas, function(psubcausa, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "form-group text required subcausa_sel",
                      class: "subcausa" + index
                    },
                    [
                      _vm._m(1, true),
                      _vm._v(" "),
                      _c("cmp-v-select", {
                        key: _vm.refreshIndex,
                        staticClass: "col-sm-11 subcausa_vsel",
                        attrs: {
                          name: _vm.controlName("[subcausa_ids][]", index),
                          options: _vm.subcausas
                        },
                        model: {
                          value: _vm.psubcausas[index],
                          callback: function($$v) {
                            _vm.$set(_vm.psubcausas, index, $$v)
                          },
                          expression: "psubcausas[index]"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1 trash-col" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.removeSubCausa(index)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-trash" }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(index))])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "button-add" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-xs",
                      on: {
                        click: function($event) {
                          return _vm.newPSubcausa()
                        }
                      }
                    },
                    [_vm._v("\n        Incluir sub-causa\n      ")]
                  )
                ])
              ],
              2
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label text required",
        attrs: { for: "plano_causa_causa_id" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n      Causa\n    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label text required",
        attrs: { for: "plano_causa_causa_id" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n        Sub-Causa\n      ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }