var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("render-file-file", {
    attrs: { field: _vm.field2Render, action_options: _vm.actOptions }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }