var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "receber_horas_temp" } }, [
    _c(
      "form",
      {
        staticClass: "simple_form receber-horas",
        attrs: {
          id: "receber-horas-form-id",
          novalidate: "novalidate",
          action: _vm.url,
          "data-remote": "true",
          method: "post",
          "accept-charset": "UTF-8"
        }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
          _vm._v(" "),
          _c("input", {
            attrs: {
              name: "authenticity_token",
              type: "hidden",
              id: "authenticity_token"
            }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "tarefa_id" },
            domProps: { value: _vm.tarefa_id }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "table",
              {
                staticClass: "table table-inner table-vmiddle",
                attrs: { id: "table_horas" }
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  { attrs: { id: "membros" } },
                  _vm._l(_vm.bancos, function(banco, index) {
                    return _c("tr", [
                      _c("td", { staticClass: "p-t-0 p-b-0 text-left" }, [
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: _vm.controlName("receber[][id]", index)
                          },
                          domProps: { value: banco.tipo_insumo_id }
                        }),
                        _vm._v(
                          "\n                  " +
                            _vm._s(banco.tipo_insumo_nome) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "p-t-0 p-b-0 text-left" }, [
                        _vm._v(_vm._s(banco.unidade))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                        _vm._v(_vm._s(_vm.number2strBr(banco.valor_unit, 2)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                        _vm._v(_vm._s(_vm.number2strBr(banco.quantidade, 4)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "p-t-0 p-b-0 text-right" },
                        [
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: _vm.controlName(
                                "receber[][valor_unit]",
                                index
                              )
                            },
                            domProps: { value: _vm.bancos[index].valor_unit }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: _vm.controlName("receber[][receber]", index)
                            },
                            domProps: { value: _vm.bancos[index].doar }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.bancos[index].tipo_insumo_id,
                                expression: "bancos[index].tipo_insumo_id"
                              }
                            ],
                            attrs: {
                              type: "hidden",
                              name: _vm.controlName(
                                "receber[][tipo_insumo_id]",
                                index
                              )
                            },
                            domProps: {
                              value: _vm.bancos[index].tipo_insumo_id
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.bancos[index],
                                  "tipo_insumo_id",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.bancos[index].destinatario,
                                expression: "bancos[index].destinatario"
                              }
                            ],
                            attrs: {
                              type: "hidden",
                              name: _vm.controlName(
                                "receber[][insumo_destinatario]",
                                index
                              )
                            },
                            domProps: { value: _vm.bancos[index].destinatario },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.bancos[index],
                                  "destinatario",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("vue-numeric", {
                            staticClass: "form-control banco",
                            attrs: {
                              separator: ".",
                              precision: 4,
                              value: "banco.doar",
                              min: 0.0,
                              max: banco.quantidade,
                              id: "horas_" + index + banco.id,
                              "on:change": _vm.alocaDoacao(index)
                            },
                            model: {
                              value: banco.doar,
                              callback: function($$v) {
                                _vm.$set(banco, "doar", $$v)
                              },
                              expression: "banco.doar"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: banco.destinatario,
                                expression: "banco.destinatario"
                              }
                            ],
                            staticClass: "select select2 input-control",
                            staticStyle: { width: "inherit" },
                            attrs: {
                              "on:change": _vm.alocaDoacao(index),
                              id: "select_dest_" + index
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  banco,
                                  "destinatario",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(banco.destinatarios, function(dest) {
                            return _c(
                              "option",
                              { domProps: { value: dest.value } },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(dest.label) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "p-t-0 p-b-0 text-right" }, [
                        _vm._v(_vm._s(_vm.doacaoEmReal(index)))
                      ])
                    ])
                  }),
                  0
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _vm._m(2)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Receber horas/valores do banco")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "30%" } }, [
          _vm._v("Nome")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "5%" } }, [
          _vm._v("Unidade")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "5%" } }, [
          _vm._v("Custo")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Disponivel")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "10%" } }, [
          _vm._v("Receber")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Para insumo")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "15%" } }, [
          _vm._v("Em R$")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          { staticClass: "btn", attrs: { "data-dismiss": "modal", href: "#" } },
          [_vm._v("Fechar")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-10" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { name: "doit", type: "submit" }
          },
          [_vm._v("Confirmar a doação\n          ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }