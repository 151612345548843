<template>
  <div class="modal-content">
    <div class="modal-header" id="modal-header">
      <form-cab :header="options.form_header"></form-cab>
    </div class="modal-header">
    <div class="modal-body row" id="modal-body">
      <form novalidate="novalidate" class="simple_form edit_anexo"
            id="nova_versao">

        <div class="col-sm-12">
          <h3>Documento</h3>
        </div>

        <div class="col-sm-3">
          <div class="form-group string optional arquivo_numero">
            <label for="arquivo_numero" class="control-label string optional">Numero</label>
            <input type="text" value="" name="arquivo[numero]"
                   id="arquivo_numero" class="form-control string optional"
                   v-model="arquivo.numero">
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group datepicker optional arquivo_emissao">
            <label for="arquivo_emissao" class="control-label datepicker optional">Emissão</label>

            <cmp-datepicker
              id="arquivo_emissao"
              :value="(arquivo.emissao || '')"
              v-model="arquivo.emissao">
            </cmp-datepicker>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group datepicker optional arquivo_validade">
            <label for="arquivo_validade" class="control-label datepicker optional">Validade</label>

            <cmp-datepicker
              id="arquivo_validade"
              :value="(arquivo.validade || '')"
              v-model="arquivo.validade">
            </cmp-datepicker>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group string optional arquivo_dias_para_renovacao">
            <label for="arquivo_dias_para_renovacao" class="control-label string optional">Dias para renovação</label>
            <input type="text" value="" name="arquivo[dias_para_renovacao]"
                   id="arquivo_dias_para_renovacao" class="form-control string optional"
                   v-model="arquivo.dias_para_renovacao">
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group text optional arquivo_local_arquivo_id">
            <label for="arquivo_local_arquivo_id" class="control-label text optional">Arquivado em</label>
            <cmp-v-select
                    input_class="form-control select select2"
                    :options="options.local_arquivos"
                    :required="false"
                    :multiple="false"
                    :value="arquivo.local_arquivo_id"
                    v-model="arquivo.local_arquivo_id">
            </cmp-v-select>
          </div>
        </div>

        <div class="col-sm-12">
          <vue-dropzone
                        ref="myVueDropzone"
                        id="dropzone"
                        :options="dzOptions()"
                        v-on:vdropzone-sending="addParams"

                        v-on:vdropzone-success="oneSucess"
                        v-on:vdropzone-error="errorInspect">
          </vue-dropzone>

        </div>

      </form>


    </div class="modal-body">

    <div class="modal-footer">
      <div class="pull-right">
        <div class="btn-group">
          <button type="button"
                  class="btn btn-w-m btn-primary active m-r-5"
                  @click="saveVersao()">
            {{ saveVersaoBtnCaption() }}
          </button>
          <a type="button"
             class="btn btn-w-m btn-default"
             data-dismiss="modal"
          >Fechar</a>
        </div>
      </div>
    </div class="modal-footer">

  </div>
</template>

<script>

  import inputUtils     from 'packs/components/mixins/inputUtils.vue'
  import FormCab        from 'packs/components/form/form-cab.vue'
  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'

  import vue2Dropzone from 'vue2-dropzone' ;
  // import 'vue2-dropzone/dist/vue2Dropzone.css' ;

  export default {
    name: 'UploadVersao',
    mixins: [inputUtils],
    components: {
      'form-cab': FormCab,
      'cmp-datepicker': CompDatePicker,
      'cmp-v-select'  : CmpVSelect,
      'vue-dropzone': vue2Dropzone
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'upload_versao',
        opts: this.options,
        arquivo: this.options.arquivo,

        token: this.options.token,

        uploads: {
          multiplos: false,
          agrupar: true
        },

        criar_nova_versao: true

      }
    },
    mounted: function() {
    },
    methods: {
      isNovaVersao: function () {
        return !(this.arquivo.id)
      },
      saveVersaoUrl: function() {
        var uurl = this.options.arquivos_url ;
        if (! this.isNovaVersao()) {
          uurl = uurl + '/' ;
          uurl = uurl + this.arquivo.id ;
        }
        uurl = uurl + '.json' ;
        return uurl ;
      },
      saveVersaoBtnCaption: function () {
        return (this.isNovaVersao() ? 'Salvar' : 'Atualizar')
      },
      saveVersao: function(maisUm) {
        var that = this;
        $.ajax({
          method: (this.isNovaVersao() ? 'post' : 'put'),
          data: {
            arquivo: {
              anexo_id:   that.arquivo.anexo_id,
              user_id:    that.arquivo.user_id,
              title:      that.arquivo.title,

              numero:     that.arquivo.numero,
              emissao:    that.arquivo.emissao,
              validade:   that.arquivo.validade,
              local_arquivo_id: that.arquivo.local_arquivo_id,
              dias_para_renovacao:   that.arquivo.dias_para_renovacao
            }
          },
          url: that.saveVersaoUrl(),
          success: function(res) {
            that.anexo = res.record ;
            window.vm.alert('success', (res.msg || 'Anexo atualizado com sucesso!')) ;
            window.vm.refresh(that.options.target_refresh)
            if (this.options.action == 'nova_versao') {
              $('#modal-main').modal('hide')
            }
          },
          error: function(res) {
            window.vm.alert('warning', 'Erro ao tentar gravar versão!') ;
          }
        });
      },
      addParams: function (file, xhr, formData) {
        formData.append( 'anexo_id', (this.arquivo.anexo_id || 0) )
        formData.append( 'responsavel', (this.arquivo.responsavel || '') )

        formData.append( 'title',  (this.arquivo.title  || '') )
        formData.append( 'numero',  (this.arquivo.numero  || '') )
        formData.append( 'emissao',  (this.arquivo.emissao  || '') )
        formData.append( 'validade', (this.arquivo.validade || '') )
        formData.append( 'local_arquivo_id', (this.arquivo.local_arquivo_id || '') )
        formData.append( 'dias_para_renovacao', (this.arquivo.dias_para_renovacao || '') )

        formData.append( 'parent_id', this.arquivo.parent_id )

        formData.append('authenticity_token', this.token);
      },
      oneSucess: function(par1, par2) {
        // So atualizamos o id do anexo para agrupar.
        // Se for para Criar um anexo a cada doc, continuamos amndando com id 0
        if (par2.record.id) {
          this.$set(this.arquivo, 'id', par2.record.id) ;

        }
        window.vm.alert('success', (par2.msg || 'Documento carregado com sucesso!')) ;
        window.vm.refresh(this.options.target_refresh)
//        if (this.options.action == 'nova_versao') {$('#modal-main').modal('hide')}
      },
      errorInspect: function (pa1, pa2, pa3) {
        // body...
      },
      refresh_table: function() {
        window.vm.refresh(this.options.target_refresh)
      },
      dzOptions: function() {
        var opts = {
          url: this.options.arquivos_url,
          dictDefaultMessage: 'Clique ou arraste e solte a nova versão do documento aqui',
          dictFileTooBig: 'Documento de {{filesize}} excede o limite de {{maxFilesize}}',
          maxFiles: 1,
          timeout: null,
          parallelUploads: 1 // this.parallelUploadsFiles
        }
        return opts
      }
    },
    computed: {
    }

  };
</script>
