<template>
  <div :class="klass"
       v-if="monted">
    <div class="form-group text required plano_causa_causa_id">
      <label for="plano_causa_causa_id"
             class="control-label text required">
        <abbr title="obrigatorio">*</abbr>
        Causa
      </label>
      <cmp-v-select :name="controlName('[causa_id]')"
                    :options="causas"
                    v-model="causa_id">
      </cmp-v-select>
    </div>
    <div v-if="(causa_id > 0)" class="sub-causas-list">
      <div v-for="(psubcausa, index) in comp_subcausas"
           class="form-group text required subcausa_sel"
           :class="'subcausa'+ index">
        <label for="plano_causa_causa_id"
               class="control-label text required">
          <abbr title="obrigatorio">*</abbr>
          Sub-Causa
        </label>
        <cmp-v-select :name="controlName('[subcausa_ids][]', index)"
                      :options="subcausas"
                      :key="refreshIndex"
                      v-model="psubcausas[index]"
                      class="col-sm-11 subcausa_vsel">
        </cmp-v-select>
        <div class="col-sm-1 trash-col">
          <a @click="removeSubCausa(index)">
             <i class="fa fa-trash"></i>
             <span>{{ index }}</span>
          </a>
        </div>

      </div>
      <div class="button-add">
        <a class="btn btn-primary btn-xs"
           @click="newPSubcausa()">
          Incluir sub-causa
        </a>
      </div>
    </div>

  </div>
</template>

<script>

  import CmpVSelect     from './../comp/cmp-v-select.vue'
  import inputUtils     from './../mixins/inputUtils.vue'

  export default {
    name: 'FormPlanoCausa',
    components: {
      'cmp-v-select'  : CmpVSelect
    },
    props: ['options'],
    mixins: [inputUtils],
    data: function () {
      return {
        klass: 'form-plano-causa',
        refreshIndex: 0,
        monted: false,

        model_name: this.options.model_name,

        causa_id: 0,
        psubcausas: [],

        nada: 'nada'
      }
    },
    mounted: function() {
      this.causa_id = this.options.causa.causa_id ;
      this.psubcausas = this.options.causa.subcausas ;
      // this.refresh() ;
      this.monted = true

    },
    computed: {
      causas: function() {
        return this.options.causas_obj ;
      },
      subcausas: function() {
        var that = this;
        var filtered = this.options.subcausas_obj ;
        if (this.causa_id != 0) {
          filtered = filtered.filter(function(subcausa) {
            return (subcausa.value != that.causa_id)
          })
        }
        // if (this.psubcausas.length > 0) {
        //   filtered = filtered.filter(function(subcausa) {
        //     return ! (that.psubcausas.includes(subcausa.value))
        //   })
        // }
        return filtered ;
      },
      comp_subcausas: function() {
        return this.psubcausas ;
      }
    },
    methods: {
      newPSubcausa: function() {
        this.psubcausas.push(0)
      },
      removeSubCausa: function(index) {
        this.psubcausas.splice(index, 1);
        this.$set(this, 'refreshIndex', this.refreshIndex + 1);
      }
    }
  };
</script>

<style scoped>
  .subcausa_sel {
    margin-left: 20px;
  }
  .subcausa_vsel {
    padding-left: 0px;
  }
  .button-add {
    padding: 15px;
  }
  div.trash-col {
    width: 5%;
    font-size: large;
  }
  div.trash-col i{
    color: gray;
  }
</style>
