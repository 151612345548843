var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group date datepicker",
      class: _vm.extraReqWidthClass(_vm.field, "has-error")
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label date datepicker",
          class: _vm.field.required ? "required" : "optional",
          attrs: {
            for: _vm.controlId(_vm.field.name),
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.field.description
          }
        },
        [
          _vm.field.required
            ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")
        ]
      ),
      _vm._v(" "),
      _vm.value
        ? _c("cmp-datepicker", {
            attrs: {
              input_class: "form-control date v-datepicker",
              required: _vm.field.required,
              type: "datepicker",
              name: _vm.controlName(_vm.field.name),
              value: _vm.value,
              id: _vm.controlId(_vm.field.name)
            }
          })
        : _c("cmp-datepicker", {
            attrs: {
              input_class: "form-control date v-datepicker",
              required: _vm.field.required,
              type: "datepicker",
              name: _vm.controlName(_vm.field.name),
              id: _vm.controlId(_vm.field.name)
            },
            model: {
              value: _vm.compFieldValue,
              callback: function($$v) {
                _vm.compFieldValue = $$v
              },
              expression: "compFieldValue"
            }
          }),
      _vm._v(" "),
      _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }