<template>
  <div :class="klass">

    <multiselect
      :multiple="true"
      :taggable="true"

      selectedLabel="Selecionado"
      selectLabel=""
      deselectLabel="Remover?"
      :closeOnSelect="false"
      placeholder=""

      v-model="particips"
      :options="users"
      track-by="value"
      label="label"
      @tag="addTag"

    >
    </multiselect>

    <div v-for="(part, index) in particips">
      <input type="hidden"
             :name="controlName('[lista][]', index)"
             :value="part.label">
    </div>

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style>
.multiselect__option--selected.multiselect__option--highlight {
    background: #cabcbc !important;
    color: #fff;
}
</style>

<script>

  import Multiselect from 'vue-multiselect'
  import inputUtils from 'packs/components/mixins/inputUtils.vue'

  export default {
    name: 'ReuniaoParticipantesSelect',
    mixins: [inputUtils],
    components: {
     'multiselect': Multiselect
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr_details',
        model_name: 'participante',
        opts: this.options,
        users: this.options.users,
        particips: this.options.participantes
      }
    },
    methods: {
      addTag (newTag) {
        const tag = {
          label: newTag,
          value: newTag
        }
        this.users.push(tag)
        this.particips.push(tag)
      }
    },
    computed: {
      lista_particps: function() {
        var res = [];
        var cont = 0 ;
        this.particips.forEach(function(part) {
          res[cont] = part.label ;
          cont = cont + 1 ;
        })
        return JSON.stringify(res);
      }
    }

  };
</script>
