var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group string",
      class: _vm.extraReqWidthClass(_vm.field, "has-error")
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label string",
          class: _vm.field.required ? "required" : "optional",
          attrs: {
            for: _vm.controlId(_vm.field.name),
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.field.description
          }
        },
        [
          _vm.field.required
            ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.field.label) + "\n\n    "),
          _c("input", {
            ref: "file" + _vm.field.name,
            staticClass: "form-control hidden",
            staticStyle: { position: "absolute", top: "-35400px" },
            attrs: { type: "file", id: _vm.controlId(_vm.field.name) },
            on: {
              change: function($event) {
                return _vm.handleFileUpload("file" + _vm.field.name)
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.cfileName,
            expression: "cfileName"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text" },
        domProps: { value: _vm.cfileName },
        on: {
          click: function($event) {
            return _vm.addFile("file" + _vm.field.name)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.cfileName = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              return _vm.addFile("file" + _vm.field.name)
            }
          }
        },
        [_vm._v("Selecionar arquivo")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }