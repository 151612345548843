// cmp-select2.vue
<template>
  <select>
    <slot></slot>
  </select>

</template>

<script>

  export default {
    name: 'cmp-select2',
    props: ['adata', 'options'],
    data: function () {
      return {
        settings:  {
          allowClear: true,
          language: "pt-BR",
          // templateResult: function (item) { return item.in_eng },
          // templateSelection: function (item) { return item.in_eng },
          width: "100%"
        },
        return_obj: false
      }
    },
    mounted: function () {
      if (this.options) {
        if (typeof(this.options.returnObject) !== 'undefined') {
          this.return_obj = this.options.returnObject
          delete this.options.returnObject
        }
      };
      var vm = this
      $(this.$el)
        // init select2
        .select2(this.sel2_settings)
        .val(this.value)
        .trigger('change')
        // emit event on change.
        .on('change', function () {
          vm.$emit('input', vm.resultReturn())
        });
        vm.$emit('input', vm.resultReturn())
    },
    computed: {
      sel2_settings: function () {
        var sets = { data: this.adata }
        return Object.assign(sets, this.settings, this.options)
      },
    },
    methods: {
      resultReturn: function () {
        if (this.return_obj) {
          var select_data = $(this.$el).select2('data')
          if (select_data.length > 0) {
            var brtRes = select_data[0]
            delete brtRes.selected
            delete brtRes.disabled
            delete brtRes.element
            return brtRes;
          } else { return null }
        } else {
          return $(this.$el).val()
        }
      }
    },
    watch: {
      value: function (value) {
        // update value
        $(this.$el).val(value)
      },
      adata: function (adata) {
        // update adata
        var newSets = Object.assign({ data: adata }, this.settings, this.options)
        $(this.$el).empty().select2(newSets)
      }
    },
    destroyed: function () {
      $(this.$el).off().select2('destroy')
    }
  }
</script>
