// render_utils.vue

<script>
  export default {
    methods: {
      controlName: function(att_name, seq) {
        var res = att_name.toString() ;
        if (this.model_name) {
          res = this.model_name + '[' + res + ']'
        }
        if (typeof seq !== 'undefined') {
          res = res + '[]'
          res = res.replace('[]', '[' + seq.toString() + ']');
        }
        return res ;
      },
      controlId: function(att_name, seq) {
        var res = att_name.toString() ;
        if (this.model_name) {
          res = this.model_name + '_' + res
        }
        if (typeof seq !== 'undefined') {
          res = res + seq.toString();
        }
        return res ;
      },
      extraReqWidthClass: function(field, errorClass) {
        var klass = ''
        if (field.required) {
          klass = 'required '
        }
        if (field.widthClass) {
          klass = klass + 'col-sm-' + field.widthClass + ' ' ;
        }
        if (this.error && errorClass) {
          klass = klass + errorClass ;
        }
        return klass;
      },
      temError: function(error) {
        return (error ? true : false)
      },
      errorsStr: function(err_obj, fields) {
        var afields = (! $.isArray(fields)) ? [fields] : fields ;
        var ret = [] ;
        if (err_obj == undefined) { return null ;}
        afields.forEach((field, index) => {
          if (err_obj[field] && (err_obj[field].length > 0)) {
            ret = ret.concat(err_obj[field]);
          }
        })
        return ret.join(',') ;
      }
    },
    computed: {
      compFieldValue: {
        get() {
          return this.$store.state[this.store_name]['model'][this.field.name]
        },
        set(nvalue) {
          this.$store.dispatch(this.store_name + '/setField',
            { name: this.field.name,
              value: nvalue })
        }
      }
    }
  }
</script>
