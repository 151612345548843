<template>
  <div class="form-group date datepicker"
       :class="extraReqWidthClass(field)">
    <label class="control-label date datepicker"
           :class="field.required ? 'required' : 'optional'"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>
    <cmp-datetime class="form-control date datepicker"
           :class="field.required ? 'required' : 'optional'"
           type="datetime"
           :name="controlName(field.name)"
           :id="controlId(field.name)">
    </cmp-datetime>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'
  import CmpDatetime from 'packs/components/comp/cmp-datetime.vue'


  export default {
    name: 'RenderDate',
    mixins: [renderUtils],
    components: {
      'cmp-datetime' : CmpDatetime
    },
    props: ['field', 'model_name', 'value'],
    data: function () {
      return {
        klass: 'ed-field-input'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
