<template>
  <render-file-file
    :field="field2Render"
    :action_options="actOptions">
  </render-file-file>
</template>

<script>

  import renderFile from './render_file_file.vue'

  export default {
    name: 'RenderFileSolicitacao',
    components: {
      'render-file-file' : renderFile
    },
    props: ['url', 'seq'],
    data: function () {
      return {
        klass: 'ed-field-input-sol',
        file:  '',
        filename: null
      }
    },
    computed: {
      field2Render: function() {
        return {
          label:      'Arquivo adicionado (' + this.seq + ')',
          name:       'file_to_load',
          type:       'file',
          subtype:    'file',
          widthClass: '12' } ;
      },
      actOptions: function() {
        return {
          url: this.url
        }
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
