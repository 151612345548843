// doar_horas.vue
<template id="doar_horas_temp">
  <div id="doar_horas_temp">

    <form id="doar-horas-form-id"
              novalidate="novalidate"
              class="simple_form doar-horas"
              :action="url"
              data-remote="true"
              method="post"
              accept-charset="UTF-8">

      <div class='modal-header'>
        <h4 class='modal-title'>Doar horas/valores para o banco</h4>
      </div>
      <div class='modal-body'>


        <input name="utf8" type="hidden" value="✓">
        <input name="authenticity_token"
               type="hidden"
               id="authenticity_token">
        <input type="hidden"
               name='tarefa_id'
               :value="tarefa_id">

        <div class="row">

          <table  class="table table-inner table-vmiddle"
                  id="table_horas">
            <thead>
              <tr>
                <th class="text-left" style="width: 50%">Nome</th>
                <th class="text-left" style="width:  5%">Unidade</th>
                <th class="text-right" style="width: 15%">Disponivel</th>
                <th class="text-right" style="width: 15%">Doar</th>
                <th class="text-right" style="width: 15%">Saldo</th>
              </tr>
            </thead>
            <tbody id="membros">
              <tr v-for="(hora, index) in horas" v-if="hora.pode_doar == 1">

                <td class="p-t-0 p-b-0 text-left">
                  <input type="hidden"
                         :name="controlName('doar[][id]',index)"
                         :value="hora.id">
                  {{ hora.nome }}
                </td>
                <td class="p-t-0 p-b-0 text-left">{{ hora.unidade }}</td>
                <td class="p-t-0 p-b-0 text-right">{{ number2strBr(hora.quantidade, 4) }}</td>
                <td class="p-t-0 p-b-0 text-right">

                  <input type="hidden"
                         :name="controlName('doar[][doar]',index)"
                         :value="horas[index].doar">
                  <input type="hidden"
                         :name="controlName('doar[][insumo_id]',index)"
                         :value="horas[index].insumo_id">
                  <input type="hidden"
                         :name="controlName('doar[][saldo]',index)"
                         :value="saldoHorasNum(index)">

                  <vue-numeric
                    class="form-control horas"
                    separator="." :precision="4"
                    v-model="horas[index].doar"
                    :value="horas[index].doar"
                    :min="0.0"
                    :max="hora.quantidade"
                    :on:change="alocaDoacao()"
                    :id="'horas' + hora.id"
                  ></vue-numeric>
                </td>
                <td class="p-t-0 p-b-0 text-right">{{ saldoHora(index) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">

          <div class="col-sm-7">
            <h4 class='modal-title'>Após doação</h4>

          </div>
<!--           <div class="row modal-header">
          </div> xxxx
 -->
          <table  class="table table-inner table-vmiddle"
                  id="table_resultado">
            <thead>
              <tr>
                <th class="text-left" style="width: 50%">Nome</th>
                <th class="text-left" style="width:  5%">Unidade</th>
                <th class="text-right" style="width: 15%">Saldo</th>
              </tr>
            </thead>
            <tbody id="membros">
              <tr v-for="(hora, index) in cmps"
                  v-if="(hora.pode_doar == 0) || (saldoCmpsHoraNum(index) > 0)" >

                <td class="p-t-0 p-b-0 text-left">
                  <input type="hidden"
                         :name="controlName('manter[][id]',index)"
                         :value="hora.id">
                  <input type="hidden"
                         :name="controlName('manter[][doar]',index)"
                         :value="cmps[index].doar">
                  <input type="hidden"
                         :name="controlName('manter[][manter]',index)"
                         :value="saldoCmpsHoraNum(index)">

                  {{ hora.nome }}
                </td>
                <td class="p-t-0 p-b-0 text-left">{{ hora.unidade }}</td>
                <td class="p-t-0 p-b-0 text-right">{{ saldoCmpsHora(index) }}</td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
      <div class="modal-footer">
        <div class="col-sm-2">
          <a class="btn text-left" data-dismiss="modal" href="#">Fechar</a>
        </div>
        <div class="col-sm-10">
          <button class="btn btn-primary"
                  name="doit"
                  type="submit">Confirmar a doação
          </button>
        </div>
      </div>


    </form>
  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  import numberMask from 'packs/components/mixins/numberMask.vue'
  import VueNumeric from 'vue-numeric';

  export default {
    name: 'doar_horas',
    mixins: [inputUtils, numberMask],
    props: ['tarefa_id', 'horasdisp', 'compos'],
    components: {
      'vue-numeric'          : VueNumeric
    },
    data: function () {
      return {
        model_name: '',
        loaded: false,
        horas: this.horasdisp,
        cmps: this.compos
      }
    },
    mounted: function () {
      moment.locale('pt-br');

      var csrftoken = $('meta[name="csrf-token"]').attr('content');
      $('input#authenticity_token').val(csrftoken) ;
    },
    watch: {
      // equipe_id: function(new_equipe_id) {
      //   if (this.loaded) {
      //     this.atuaEquipe(new_equipe_id) ;
      //   }
      // }
    },
    computed: {
      url: function () {
        return '/horas_banco/' + this.tarefa_id + '/doar' ;
      }
    },
    methods: {
      saldoHorasNum: function(index) {
        return this.horas[index].quantidade - this.horas[index].doar ;
      },
      saldoHora: function(index) {
        return this.number2strBr( this.saldoHorasNum(index), 4 );
      },
      saldoCmpsHoraNum: function(index) {
        return this.cmps[index].quantidade - this.cmps[index].doar ;
      },
      saldoCmpsHora: function(index) {
        return this.number2strBr( this.saldoCmpsHoraNum(index), 4 );
      },
      alocaDoacao: function() {
        this.cmps.forEach((insu, index) => {
          insu.doar = 0.0 ;
        });
        this.horas.forEach((hora, index) => {
          if (hora.doar > 0) {
            // Busca insumo
            var index_insumo_dest = -1
            var cmp_dest = null
            var multiplicador = 1  // Valor unitário
            this.cmps.forEach((cmp, index) => {
              if ((cmp.insumo_id == hora.insumo_id) && (index_insumo_dest == -1)) {
                index_insumo_dest = index;
                cmp_dest = cmp;
              }
            });

            if ((index_insumo_dest == -1) && (hora.insumo_id == -88)) {
              var insumoverba = {
                id:   -88,
                insumo_id: -88,
                nome: 'Verba (R$)',
                unidade: 'R$',
                quantidade: 0,
                doar:   0.0,
                saldo:  0.0,
                pode_doar: 0
              }
              this.cmps.push( insumoverba );
              index_insumo_dest = this.cmps.length - 1;
              cmp_dest = this.cmps[index_insumo_dest] ;
            }

            if (cmp_dest) {
              cmp_dest.doar = cmp_dest.doar + ( hora.doar * multiplicador ) ;
            }
          }
        });
      }
    }
  }


</script>
