var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _vm.options.title_label
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.options.title_label) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control string optional",
                attrs: { type: "text", readonly: "" },
                domProps: { value: _vm.options.title_value }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.ask_data_vencimento
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.options.data_vencimento_label) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("cmp-datepicker", {
                attrs: {
                  id: "input_data_vencimento",
                  name: "input_data_vencimento"
                },
                model: {
                  value: _vm.data_vencimento,
                  callback: function($$v) {
                    _vm.data_vencimento = $$v
                  },
                  expression: "data_vencimento"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.ask_select
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.options.ask_select.title) +
                      "\n        "
                  )
                ]
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c("vue-dropzone", {
          ref: "myVueDropzone",
          attrs: {
            id: "dropzone",
            options: _vm.dz_options,
            language: _vm.langOptions,
            dictDefaultMessage: "Novo Texto"
          },
          on: {
            "vdropzone-sending": _vm.addParams,
            "vdropzone-params": _vm.params,
            "vdropzone-success": _vm.successUp,
            "vdropzone-error": _vm.errorUp
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }