var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel panel-default", style: _vm.hideOrShowStyle },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.group.caption != "",
              expression: "group.caption != ''"
            }
          ],
          staticClass: "panel-heading",
          staticStyle: { display: "flex" }
        },
        [
          _c("div", { staticClass: "title", staticStyle: { float: "left" } }, [
            _c("h5", [_vm._v(_vm._s(_vm.group.caption))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-tools" })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "panel-body",
          style: _vm.hideOrShowStyle,
          attrs: {
            "aria-expanded": "true",
            id: "render-group-body-" + _vm.index
          }
        },
        [
          _c(
            "div",
            { ref: "render_slot", staticClass: "fields-group" },
            _vm._l(_vm.json_fields, function(field, index) {
              return _c(_vm.renderFieldName(field), {
                key: index,
                tag: "component",
                attrs: {
                  field: field,
                  model_name: _vm.model_name_2ex,
                  value: _vm.fieldValue(field),
                  error: _vm.fieldError(field),
                  store_name: _vm.options.storename,
                  action_options: _vm.options2filed
                }
              })
            }),
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }