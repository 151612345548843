var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.field.values.length > 1
    ? _c(
        "div",
        {
          staticClass: "form-group check_boxes boolean",
          class: _vm.extraReqWidthClass(_vm.field, "has-error")
        },
        [
          _c(
            "label",
            {
              staticClass: "control-label check_boxes boolean",
              class: _vm.field.required ? "required" : "optional",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: _vm.field.description
              }
            },
            [
              _vm.field.required
                ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.field.values, function(checkbox) {
            return _c("div", { staticClass: "form-check" }, [
              _c("input", {
                staticClass: "form-check-input check_boxes",
                attrs: {
                  type: "checkbox",
                  id: _vm.controlId(_vm.field.name) + "_" + checkbox.value,
                  name: _vm.controlName(_vm.field.name) + "[]"
                },
                domProps: {
                  value: checkbox.value,
                  checked:
                    _vm.value && _vm.value.includes(checkbox.value)
                      ? "checked"
                      : null
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: {
                    for: _vm.controlId(_vm.field.name) + "_" + checkbox.value
                  }
                },
                [_vm._v("\n      " + _vm._s(checkbox.label) + "\n    ")]
              )
            ])
          }),
          _vm._v(" "),
          _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
        ],
        2
      )
    : _c(
        "div",
        {
          staticClass: "form-group check_boxes boolean form-check",
          class: _vm.extraReqWidthClass(_vm.field, "has-error")
        },
        [
          _c("input", {
            attrs: {
              type: "hidden",
              name: _vm.controlName(_vm.field.name),
              value: "0"
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.field.values, function(checkbox) {
            return _c("input", {
              staticClass: "boolean optional",
              attrs: {
                type: "checkbox",
                name: _vm.controlName(_vm.field.name),
                id: _vm.controlId(_vm.field.name)
              },
              domProps: {
                value: checkbox.value,
                checked: checkbox.selected ? "checked" : null
              }
            })
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "boolean optional",
              attrs: { for: _vm.controlId(_vm.field.name) }
            },
            [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")]
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }