// acao/repeat-options.vue
<template>
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group datepicker required acao_data_prevista"
           v-bind:class="{ 'has-error': errorsStr(options.errors, 'data_prevista') }">

        <label for="acao_data_prevista"
               class="control-label datepicker required">
          <abbr title="obrigatorio">*</abbr>
          Data prevista
        </label>
        <cmp-datepicker
          id="acao_data_prevista"
          :name="input_data_name"
          v-model="data_prevista">
        </cmp-datepicker>
        <span class="help-block">{{ errorsStr(options.errors, 'data_prevista') }}</span>
      </div>
    </div>
    <div class="col-sm-9"
         v-if="new_record">
      <div class="form-group repeticao">
        <label for="repeticao"
               class="control-label repeticao">
          Gerar múltiplas Ações periódicas. Frequência:
        </label>
        <cmp-v-select
          input_class="form-control select select2"
          :options="opcoes_repeat"
          :required="true"
          name="acao[repeat_selected]"
          :value="repeat_selected"
          v-model="repeat_selected">
        </cmp-v-select>
      </div>

      <div id="repeat_params"
           v-if="repeat_selected > 0">
        <div class="col-sm-6 form-group datepicker required acao_data_limite">
          <label for="acao_data_limite"
                 class="control-label datepicker required">
            <abbr title="obrigatorio">*</abbr>
            Gerar ações até
          </label>
          <cmp-datepicker
            id="acao_data_limite"
            name="acao[data_limite]"
            v-model="data_limite">
          </cmp-datepicker>
        </div>

        <div class="col-sm-6 form-group text optional repeat_number">
          <label for="repeat_number"
                 class="control-label text optional">
            <abbr title="obrigatorio">*</abbr>
            Número máximo de ações à gerar
          </label>
          <cmp-autonumeric
            :required="true"
            name="acao[numero_limite]"
            id="repeat_numero_limite"
            v-model="numero_limite"
            :decimal_places="0"
            :min="2"
            :max="100"
          ></cmp-autonumeric>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'
  import cmpAutonumeric from 'packs/components/comp/cmp-autonumeric.vue'
  import inputUtils     from 'packs/components/mixins/inputUtils.vue'
  import errorStr       from 'packs/components/mixins/errorStr.vue'


  export default {
    name: 'acao-repeat-options',
    props: ['options'],
    components: {
      'cmp-datepicker': CompDatePicker,
      'cmp-v-select'  : CmpVSelect,
      'cmp-autonumeric' : cmpAutonumeric
    },
    mixins: [errorStr],
    data: function () {
      return {
        loaded: false,

        new_record: this.options.new_record,

        data_prevista: this.options.data_prevista,
        input_data_name: this.options.input_data_name,

        opcoes_repeat: [
          { label: 'Não se repete', value: 0 },
          { label: 'Semanal', value: 1 },
          { label: 'Mensal', value: 2 },
          { label: 'Trimestral', value: 3 },
          { label: 'Semestral', value: 4 },
          { label: 'Anual', value: 5 }
        ],

        repeat_selected: Number(this.options.repeat_selected),
        data_limite:     this.options.data_limite,
        numero_limite:   Number(this.options.numero_limite),

        nada: 'nada'
      }
    },
    created: function () {
    },
    methods: {
    },

    computed: {
    }
  }

</script>

