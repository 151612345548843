<template>
  <div class="form-group string"
       :class="extraReqWidthClass(field, 'has-error')">
<!--
       :class="{ 'has-error': errorsStr(options.errors, field.name) }">
 -->
    <label class="control-label string"
           :class="field.required ? 'required' : 'optional'"
           :for="controlId(field.name)"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>
    <input v-if="value"
           class="form-control string"
           :class="field.required ? 'required' : 'optional'"
           type="text"
           :maxlength="field.maxlength"
           :placeholder="field.placeholder"
           :value="value"
           :name="controlName(field.name)"
           :id="controlId(field.name)">
    <input v-else
           class="form-control string"
           :class="field.required ? 'required' : 'optional'"
           type="text"
           :maxlength="field.maxlength"
           :placeholder="field.placeholder"
           v-model="compFieldValue"
           :name="controlName(field.name)"
           :id="controlId(field.name)">
    <span class="help-block">{{ error }}</span>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'

  export default {
    name: 'RenderTextText',
    mixins: [renderUtils],
    components: {
    },
    props: ['field', 'model_name', 'value', 'error', 'store_name'],
    data: function () {
      return {
        klass: 'ed-field-input'
      }
    },
    computed: {
    },
    mounted: function () {
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
