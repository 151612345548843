<template>
  <div v-if="field.values.length > 1"
       class="form-group check_boxes boolean"
       :class="extraReqWidthClass(field, 'has-error')">
    <label class="control-label check_boxes boolean"
           :class="field.required ? 'required' : 'optional'"
           data-toggle="tooltip"
           data-placement="top"
           :title="field.description">
      <abbr v-if="field.required"
            title="obrigatorio">*</abbr>
      {{ field.label }}
    </label>

    <div v-for="checkbox in field.values"
         class="form-check">

      <input class="form-check-input check_boxes"
             type="checkbox"
             :id="controlId(field.name) + '_' + checkbox.value"
             :name="controlName(field.name) + '[]'"
             :value="checkbox.value"
             :checked="(value && (value.includes(checkbox.value)) ? 'checked' : null)">
      <label class="form-check-label"
             :for="controlId(field.name) + '_' + checkbox.value">
        {{ checkbox.label }}
      </label>
    </div>
    <span class="help-block">{{ error }}</span>
  </div>
  <div v-else
       class="form-group check_boxes boolean form-check"
       :class="extraReqWidthClass(field, 'has-error')">

    <input type="hidden"
           :name="controlName(field.name)"
           value="0">

    <input v-for="checkbox in field.values"
           type="checkbox"
           :value="checkbox.value"
           :checked="(checkbox.selected ? 'checked' : null)"
           :name="controlName(field.name)"
           :id="controlId(field.name)"
           class="boolean optional">
    <label :for="controlId(field.name)"
           class="boolean optional">
      {{ field.label }}
    </label>
  </div>
</template>

<script>

  import renderUtils from './render_utils.vue'

  export default {
    name: 'RenderCheckbox',
    mixins: [renderUtils],
    components: {
    },
    props: ['field', 'model_name', 'value', 'error'],
    data: function () {
      return {
        klass: 'ed-checkbox'
      }
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
