// receber_horas.vue
<template id="table_apos">
  <div class="row">

    <div class="col-sm-7">
      <h4 class='modal-title'>Após doação</h4>

    </div>
<!--           <div class="row modal-header">
    </div> xxxx
-->
    <table  class="table table-inner table-vmiddle"
            id="table_resultado">
      <thead>
        <tr>
          <th class="text-left" style="width: 50%">Nome</th>
          <th class="text-left" style="width:  5%">Unidade</th>
          <th class="text-right" style="width: 10%">Agora</th>
          <th class="text-right" style="width: 10%">Depois</th>
        </tr>
      </thead>
      <tbody id="membros">

        <tr v-for="(insu, index) in insumos_apos" >

          <td class="p-t-0 p-b-0 text-left">
            <input type="hidden"
                   :name="controlName('manter[][id]',index)"
                   :value="insu.id">
            <input type="hidden"
                   :name="controlName('manter[][doar]',index)"
                   :value="insumos_apos[index].doar">
            <input type="hidden"
                   :name="controlName('manter[][manter]',index)"
                   :value="totalInsuNum(index)">

            {{ insu.nome }}
          </td>
          <td class="p-t-0 p-b-0 text-left">{{ insu.unidade }}</td>
          <td class="p-t-0 p-b-0 text-right">{{ number2strBr( insu.quantidade, 2 ) }}</td>
          <td class="p-t-0 p-b-0 text-right">{{ number2strBr( totalInsuNum(index), 2 ) }}</td>
        </tr>


      </tbody>
    </table>

  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  import numberMask from 'packs/components/mixins/numberMask.vue'

  export default {
    name: 'table_apos',
    mixins: [inputUtils, numberMask],
    props: [
      'insumos_apos'
    ],
    components: {
    },
    data: function () {
      return {
        model_name: '',
        loaded: false,
        bancos: []
      }
    },
    mounted: function () {
      moment.locale('pt-br');

    },
    watch: {
    },

    methods: {
      saldoBancosNum: function(index) {
        return this.bancos[index].quantidade - this.bancos[index].doar ;
      },
      saldoBanco: function(index) {
        return this.number2strBr( this.saldoBancosNum(index), 4 );
      },
      doacaoEmReal: function(index) {
        return this.number2strBr( this.bancos[index].doar * this.bancos[index].valor_unit, 2 ) ;
      },
      totalFinalNum: function(index) {
        return this.insumos_apos[index].quantidade - this.insumos_apos[index].doar ;
      },
      totalFinal: function(index) {
        return this.number2strBr( this.totalFinalNum(index), 4 );
      },
      totalInsuNum: function(index) {
        return this.insumos_apos[index].quantidade + this.insumos_apos[index].doar ;
      }
    }
  }

</script>
