var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _vm.options.tipo == "cronograma_equipe"
      ? _c("div", { staticClass: "col-sm-12 p-b-10" }, [
          _vm._m(0),
          _vm._m(1),
          _vm._v(" "),
          _c("p")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "fvs_plan"
      ? _c("div", { staticClass: "col-sm-12 p-b-10" }, [
          _vm._m(2),
          _vm._m(3),
          _vm._v(" "),
          _c("p")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "rns_plan"
      ? _c("div", { staticClass: "col-sm-12 p-b-10" }, [
          _c("p", [
            _vm._m(4),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("span", [
              _c("strong", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      "TRs devem começar na " + _vm.options.linha1 + "ª linha"
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._m(5)
          ]),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            _vm._l(_vm.options.colunas, function(col, index) {
              return _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
                _vm._v(
                  "\n            " +
                    _vm._s("Coluna " + col.posicao + ": " + col.nome) +
                    "\n          "
                )
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("p")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.title_label
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.options.title_label) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control string optional",
                attrs: { type: "text", readonly: "" },
                domProps: { value: _vm.options.title_value }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "orca"
      ? _c("div", { staticClass: "col-sm-5" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          Obra para atualização de orçamento\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("cmp-v-select", {
                attrs: {
                  required: true,
                  options: _vm.options.obras,
                  returnObj: true
                },
                model: {
                  value: _vm.selected_obra,
                  callback: function($$v) {
                    _vm.selected_obra = $$v
                  },
                  expression: "selected_obra"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "orca"
      ? _c("div", { staticClass: "col-sm-5" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          Código Solum da obra já existente\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.opts.solum_old,
                    expression: "opts.solum_old"
                  }
                ],
                staticClass: "form-control string optional",
                attrs: { type: "text" },
                domProps: { value: _vm.opts.solum_old },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.opts, "solum_old", $event.target.value)
                  }
                }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "orca"
      ? _c("div", { staticClass: "col-sm-5" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [
                  _vm._v(
                    "\n          Código Solum do orçamento atualizado\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.opts.solum_new,
                    expression: "opts.solum_new"
                  }
                ],
                staticClass: "form-control string optional",
                attrs: { type: "text" },
                domProps: { value: _vm.opts.solum_new },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.opts, "solum_new", $event.target.value)
                  }
                }
              })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "rns_plan"
      ? _c("div", { staticClass: "col-sm-5" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [_vm._v("\n          Negócio\n        ")]
              ),
              _vm._v(" "),
              _c("cmp-v-select", {
                attrs: {
                  required: true,
                  options: _vm.options.obras,
                  returnObj: true
                },
                model: {
                  value: _vm.selected_obra,
                  callback: function($$v) {
                    _vm.selected_obra = $$v
                  },
                  expression: "selected_obra"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.tipo == "rns_plan"
      ? _c("div", { staticClass: "col-sm-5" }, [
          _c(
            "div",
            { staticClass: "form-group string optional arquivo_responsavel" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label string optional",
                  attrs: { for: "arquivo_responsavel" }
                },
                [_vm._v("\n          Semana\n        ")]
              ),
              _vm._v(" "),
              _c("cmp-v-select", {
                key: _vm.refreshInc,
                attrs: {
                  required: true,
                  options: _vm.options.semanas,
                  returnObj: true
                },
                model: {
                  value: _vm.selected_semana,
                  callback: function($$v) {
                    _vm.selected_semana = $$v
                  },
                  expression: "selected_semana"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c("vue-dropzone", {
          ref: "myVueDropzone",
          attrs: {
            id: "dropzone",
            options: _vm.dzOptions,
            language: _vm.langOptions,
            dictDefaultMessage: "Novo Texto"
          },
          on: {
            "vdropzone-sending": _vm.addParams,
            "vdropzone-params": _vm.params,
            "vdropzone-success": _vm.successUp,
            "vdropzone-error": _vm.errorUp
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.errors.length > 0
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "ibox float-e-margins" }, [
            _vm._m(6),
            _vm._v(" "),
            _c("div", { staticClass: "ibox-content" }, [
              _c(
                "ul",
                { staticClass: "todo-list m-t error small-list" },
                _vm._l(_vm.errors, function(error_text) {
                  return _c("li", { staticStyle: { color: "red" } }, [
                    _c("span", { staticClass: "has-error" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(error_text) +
                          "\n              "
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.importados.length > 0
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "table",
            {
              staticClass: "table table-inner table-vmiddle",
              attrs: { id: "table_horas" }
            },
            [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "tbody",
                { attrs: { id: "importados" } },
                _vm._l(_vm.importados, function(impo, index) {
                  return _c("tr", { key: index + "impolin" }, [
                    _c(
                      "td",
                      {
                        key: index + "impocol1",
                        staticClass: "p-t-0 p-b-0 text-left"
                      },
                      [_vm._v(_vm._s(impo.filename))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        key: index + "impocol2",
                        staticClass: "p-t-0 p-b-0 text-left"
                      },
                      [_vm._v(_vm._s(impo.status))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        key: index + "impocol3",
                        staticClass: "p-t-0 p-b-0 text-left"
                      },
                      [
                        _c(
                          "a",
                          { attrs: { target: "_blank", href: impo.url } },
                          [
                            _vm._v(
                              "\n                 " +
                                _vm._s(impo.caption) +
                                "\n              "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_c("strong", [_vm._v("Estrutura da planilha (colunas)")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 1: Nome do serviço")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 5: Identificador do local ")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 2: Código da composição (serviço)")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 6: Nome da equipe")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 3: Data de inicio ")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 7: Unidade do canteiro")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 4: Data fim")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 8: Quantidade equivalente")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_c("strong", [_vm._v("Estrutura da planilha (colunas)")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 1: Nome do serviço (FVS)")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 4: Fim")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 2: Local")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 5: Status")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "col-sm-5 col-md-offset-1" }, [
        _vm._v("Coluna 3: Inicio ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("strong", [_vm._v("Célula A1 deve conter o código da RN")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("strong", [_vm._v("Estrutura da planilha (colunas)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h5", [_vm._v("Errors no arquivo enviado")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "40%" } }, [
          _vm._v("Arquivo Importado")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "30%" } }, [
          _vm._v("Resultado")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "30%" } }, [
          _vm._v("Resumo da importação")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }