var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.klass },
    [
      _vm.opts.action == "new"
        ? _c("cmp-v-select", {
            attrs: {
              input_class: "form-control select select2",
              options: _vm.users,
              required: true,
              name: "allocation[email]"
            },
            model: {
              value: _vm.selecteds,
              callback: function($$v) {
                _vm.selecteds = $$v
              },
              expression: "selecteds"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.opts.action == "edit"
        ? _c(
            "div",
            { staticClass: "form-group email optional alocacao_email" },
            [
              _c(
                "label",
                {
                  staticClass: "control-label email optional",
                  attrs: { for: "alocacao_email" }
                },
                [_vm._v("\n        E-mail\n      ")]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control string email optional",
                attrs: {
                  autofocus: "autofocus",
                  type: "email",
                  id: "alocacao_email",
                  readonly: ""
                },
                domProps: { value: _vm.selecteds }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }