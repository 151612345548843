var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-12" }, [
    _c(
      "div",
      { staticClass: "form-group funcao_id optional novo_membro_funcao_id" },
      [
        _c(
          "label",
          {
            staticClass: "funcao_id optional",
            attrs: { for: "novo_membro_funcao_id" }
          },
          [_vm._v("Função")]
        ),
        _vm._v(" "),
        _c("cmp-v-select", {
          attrs: {
            input_class: "form-control select select2",
            options: this.lFuncoes2Sel,
            required: true,
            multiple: false
          },
          model: {
            value: _vm.funcao_id,
            callback: function($$v) {
              _vm.funcao_id = $$v
            },
            expression: "funcao_id"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "form-group funcionario_id optional novo_membro_funcionario_id"
      },
      [
        _c(
          "label",
          {
            staticClass: "funcionario_id optional",
            attrs: { for: "novo_membro_funcionario_id" }
          },
          [_vm._v("Funcionário")]
        ),
        _vm._v(" "),
        _c("cmp-v-select", {
          attrs: {
            input_class: "form-control select select2",
            options: _vm.lFuncionarios2Sel,
            required: true,
            multiple: false,
            name: "funcionario_id"
          },
          model: {
            value: _vm.funcionario_id,
            callback: function($$v) {
              _vm.funcionario_id = $$v
            },
            expression: "funcionario_id"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }